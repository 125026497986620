import { ImSchool } from './../../services/im-models/im-school';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { ITermInfo, ITermInfoMini } from './../../typings/interfaces/school.interface';
import { IDaysOfTheWeekBoolean, ISchoolSupport, TValidSchoolSupportRepeatsOptions } from 'Src/ng2/shared/typings/interfaces/support.interface';
import { SupportStatuses } from './../../constants/support-statuses.constant';
import { IACOption } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { IOptionClickedEvent } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.component';
import { ISchoolSupportMetadata } from './../../typings/interfaces/support.interface';
import { SUPPORT_WEEK, SupportModalService, TSupportDateChangeType } from './support-modal.service';
import { Component, Inject, OnDestroy, ChangeDetectorRef, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep, each, filter, find, isEqual, map, reduce, values, uniq, compact } from 'lodash';
import { Observable, Subscription, Unsubscribable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, take } from 'rxjs/operators';
import { Tiers } from '../../constants/support-tiers.constant';
import { SupportCategories } from '../../constants/support-categories.constant';
import { BaseModalComponent } from '../base-modal.component';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';
import { CreateSupport, ICreateSupportPayload, UpdateSupport } from './../../../store/actions/supports-actions';
import { ValidRegentsPlans } from './../../constants/regents-plans.constant';
import { UtilitiesService } from './../../services/utilities/utilities.service';
import { RegentsSubject } from './../../constants/regents.constant';
import {
  SUPPORT_MODAL_CONFIG,
  ACADEMIC_SUBCATS,
  PHYS_HEALTH_SUBCATS,
  subjectsForRegentsAndAcademicCatPatches,
  SUPPORT_SCHEDULE_WEEK_OBJ,
} from './support-modal.config';
import { ISupportOptPayload, ISupportModalComponentData, TSupportModalMode } from './support-modal.interface';
import { unsubscribeComponent } from '../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import * as moment from 'moment';
import { Toggles } from '../../constants/toggles.constant';
import { ToggleService } from '../../services/toggle/toggle.service';
import { ModalsService } from '../modals.service';
import { BulkUpdateStudentSupports, IStudentSupportUpdatePayload } from 'Src/ng2/store';
import { TDistricts } from '../../typings/interfaces/district.interface';
import { RegularExpressionsUtility } from '../../utilities/regular-expressions/regular-expressions.utility';
import { SUPPORT_AND_STUDENT_SUPPORT_ACTIONS } from '../../services/mixpanel/event-interfaces/supports-action';
import { SharedEventTrackers } from '../../services/mixpanel/event-trackers/shared-tracking.service';

const supportCats = {
  REGENTS_PREP: 'REGENTS_PREP',
  ATTENDANCE: 'ATTENDANCE',
  ACADEMIC: 'ACADEMIC',
  PHYS_HEALTH_SERVICES: 'PHYS_HEALTH_SERVICES',
};

const catsWithMetaService = [supportCats.ATTENDANCE, supportCats.ACADEMIC, supportCats.PHYS_HEALTH_SERVICES];

@Component({
  selector: 'support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class SupportModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  // PRIVATE
  private mode: TSupportModalMode;
  private isCompleted: boolean = false;

  private schoolId: string;
  private schoolType: string = '';
  private district: TDistricts;
  private supportId: string;

  private dateRangeForm: FormGroup;
  private timeRangeForm: FormGroup;
  private termRangeForm: FormGroup;

  private formSubscription: Subscription; // page 1 of modal
  private formSubscription2: Subscription; // page 2 of modal

  // PUBLIC
  public supportForm: FormGroup;
  public isProfileMode: boolean = true;
  public isMultiMode: boolean = true;
  public buttonMode = null;
  public readonly buttons = SUPPORT_MODAL_CONFIG;
  public itemCount: number = 0;
  public itemType: string;
  public supportName: string;
  public supportSubtitle: string;
  public formVariant: {
    title: string;
    actionButtonLabel: string;
  };

  public disableFormSubmit: boolean;

  // date properties
  public initialStartDate: string;
  public initialEndDate: string;
  public startLimit: string = '';
  public endLimit: string;
  public rangeStartTime: string = '6:00 AM';
  public rangeEndTime: string = '9:00 PM';
  public interval = 30;
  public disableDateChange: boolean = false; // users shouldn't be able to update legacy support dates (unless they're duplicating it)
  public needsDateChangeConfirmation = false;
  public needsAttRecordDelConfirmed = false;
  public attRecordDelConfirmed = false;
  public changeRelatedStudentSupports = false;

  // Name
  placeholderName: string = 'Enter a name for your support';
  hasClearBtn: boolean = true;
  origName: string;

  // Description
  placeholderDesc: string = 'Enter a description';
  maxCharacters: number = 150;

  private tierToPicker = (tier) => {
    return {
      key: tier.key,
      human: tier.humanName,
    };
  };

  // Tier
  tiers: IPickerOption[] = Object.values(Tiers).map(this.tierToPicker);
  selectedTier: IPickerOption = this.tierToPicker(Tiers.UNSPECIFIED);
  initialTier: IPickerOption;
  tierTooltip: string = 'Tier describes the intensity and group size of a support'


  // categories
  categoryBasedMenuOptions: {
    categories: IPickerOption[];
    groupedSubcats: any;
  };

  categories: IPickerOption[];
  loadedCats;
  categoryPlaceholder: string = 'Select a category';
  selectedCats: any[] = [];
  catsWithMeta = [
    supportCats.REGENTS_PREP,
    supportCats.ACADEMIC,
    supportCats.PHYS_HEALTH_SERVICES,
    supportCats.ATTENDANCE,
  ];

  catsWithRequiredMeta = { REGENTS_PREP: true };

  // subjects
  subjects: any[];
  selectedSubject: string;
  showSubjectsField: boolean = false;
  public subjectLabel: string = 'Subject';

  // admin date
  adminDates: any[];
  regentsAdminDate: string;
  showRegentsAdminField: boolean = false;

  // subcats
  selectedSubcats: any[] = [];
  subcatBuckets: any = {};
  subcats: IPickerOption[];
  showSubcategoriesField: boolean = false;
  selectedSubcat: string;
  currentAcademicSelections = [];
  currentPhysSelections = [];
  otherSelections = [];

  // leads
  users: any[] = [];
  selectedActivityLeads: string[] = [];
  entities$: Observable<any>;

  // multiple
  isInverted: boolean = false;
  hasAllButton: boolean = false;
  hasClearButton: boolean = true;
  color: string = 'blue';
  warningIcon: string = 'error-large';

  // freq
  frequencies: IACOption[];
  frequencyIsCustom: boolean = false;
  selectedRepeats: string;
  frequencyIsOnce: boolean;
  public showDateTimePickers: boolean = false;
  public scheduleTooltip: string = 'Select a frequency to show term, date, and time fields';

  // term picker properties
  public termRangeLabel: string = 'Start and end term';
  public currentTerm: { option: IDropdownOption, index: number };
  public orderedTermDates: ITermInfo[];
  public termPickerStartOptions: IDropdownOption[] = [];
  public termPickerEndOptions: IDropdownOption[] = [];
  public selectedTerms: any;
  public termPickerOptionsMap: Map<string, any> = new Map();
  public termYearArray: string[] = [];

  weekdays: {
    human: string;
    isActive: boolean;
    validKey: string;
  }[];

  // repeatsEvery
  today: string;
  repeatsEveryOpts: IACOption[];
  selectedRepeatsEvery: string;
  repeatsEveryMapping = {
    1: '1 week',
    2: '2 weeks',
    3: '3 weeks',
    4: '4 weeks',
    5: '5 weeks',
    6: '6 weeks',
  };

  // payload
  payload: ISupportOptPayload;
  requiresMadlibRefresh: boolean;

  // navigtion
  fieldSet1IsActive: boolean;
  fieldSet2IsActive: boolean;
  childHasSecondPage: boolean;

  // template
  private supportTemplate: ISchoolSupport;

  private originalForm: FormGroup;
  private names: string[];

  private dataSubscription: Unsubscribable;

  // reassigning students when duplicating supports
  public shouldReassignStudents: boolean = false;
  private haveQueriedStudentIds: boolean = false; // query for studentIds can be time consuming and don't want duplicate calls
  private studentIds: string[];

  public v4ModeIsOn: boolean;

  constructor (
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private dateHelpers: DateHelpers,
    private utils: UtilitiesService,
    private cd: ChangeDetectorRef,
    private sms: SupportModalService,
    private imSchool: ImSchool,
    dialogRef: MatDialogRef<SupportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISupportModalComponentData,
    private toggleService: ToggleService,
    private elementRef: ElementRef,
    private modalsService: ModalsService,
    private regexUtil: RegularExpressionsUtility,
    private sharedEventTrackers: SharedEventTrackers,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { mode, schoolId, support } = cloneDeep(this.data);

    this.schoolId = schoolId;
    this.mode = mode;
    this.formVariant = this.sms.getFormVariants(mode);

    this.supportTemplate = this.createNewSupportFromTemplate();
    if (support && mode !== 'DUPLICATE') {
      this.supportId = support._id;
      this.supportTemplate._id = support._id;
    }
    this.showFieldSetOne();

    this.today = this.dateHelpers.getMomentObjForMonthDate(Date.now());
    this.initialStartDate = this.sms.getInitialDate({ startDate: support?.schedule?.startsOn, mode: this.mode, isStartDate: true });
    this.initialEndDate = this.sms.getInitialDate({ startDate: support?.schedule?.startsOn, endDate: support?.schedule?.endsOn, mode: this.mode, isStartDate: false });
    this.weekdays = values(SUPPORT_WEEK);
    this.adminDates = this.setRegentsAdminDates();
    this.frequencies = this.sms.setFrequencies();
    this.repeatsEveryOpts = this.sms.convertToACOptions([
      '1 week',
      '2 weeks',
      '3 weeks',
      '4 weeks',
      '5 weeks',
      '6 weeks',
    ]);

    // Resolve data
    const school$ = this.sms.getSchool$();
    const users$ = this.sms.getUsers$(this.schoolId);
    const supportNames$ = this.sms.getSupportNames$(this.schoolId);

    this.dataSubscription = combineLatest([school$, users$, supportNames$])
      .pipe(
        take(1),
        tap(([school, users, supportNames]) => {
          const { schoolType, district } = school;
          this.district = district;
          this.users = users;
          this.names = supportNames;
          this.schoolType = schoolType;
          this.categoryBasedMenuOptions = this.sms.getCategoryBasedOptions(schoolType);
          this.categories = this.categoryBasedMenuOptions.categories;
          this.subcatBuckets = this.categoryBasedMenuOptions.groupedSubcats;

          // term menu options
          this.orderedTermDates = this.imSchool.getOrderedTermInfoArray(school);
          this.orderedTermDates.forEach((term, index) => {
            const human = `${term.termId === '7' ? 'Summer' : `Term ${term.termId}`} ${term.schoolYear}`;
            const option = { key: term.yearTerm, human };
            const termWithDropdownOption = { ...term, option, index };
            this.termPickerStartOptions.push(option);
            this.termPickerOptionsMap.set(term.yearTerm, termWithDropdownOption);
          });
          const currentTermYear = this.imSchool.getEffectiveCurrentTermYear(school);
          this.currentTerm = this.termPickerOptionsMap.get(`${currentTermYear}`);
          this.termPickerEndOptions = this.termPickerStartOptions.slice(0, this.currentTerm.index + 1);

          // Build support form
          this.supportForm = support ? this.buildForm(this.users, support) : this.buildForm(this.users);
          this.supportForm.markAsPristine();
          this.supportForm.markAsUntouched();
          this.originalForm = cloneDeep(this.supportForm.value);
          this.setFormSubscriptions();
        }),
      )
      .subscribe();

    this.toggleV4NewSkinMode();
  }

  toggleV4NewSkinMode () : void {
    this.v4ModeIsOn = this.toggleService.getToggleState(Toggles.TOGGLE_V4_NEW_SKIN_MODE);
    if (this.v4ModeIsOn) {
      this.elementRef.nativeElement?.classList.add('v4');
    }
  }

  ngOnDestroy (): void {
    if (this.supportForm) {
      this.formSubscription.unsubscribe();
      this.formSubscription2.unsubscribe();
    }
    this.dataSubscription.unsubscribe();
  }

  public createNewSupportFromTemplate (): ISchoolSupport {
    const support = {} as ISchoolSupport;
    support._id = this.utils.createV4Uuid();
    return support;
  }

  private setRegentsAdminDates (): any[] {
    return map(ValidRegentsPlans, plan => plan);
  }

  public setLoadedCats (categories) {
    return map(categories, cat => {
      const catHumanName = SupportCategories[cat.category].humanName;
      if (this.selectedCats) this.selectedCats.push(cat.category);
      return {
        key: cat.category,
        human: catHumanName,
        metaData: cat.metaData,
      };
    });
  }

  private setInputVisibility (categories): void {
    if (categories.includes(supportCats.ACADEMIC)) {
      this.showSubjectsField = true;
      this.showSubcategoriesField = true;
      this.subjectLabel = 'Subject (optional)';
    }
    if (categories.includes(supportCats.REGENTS_PREP)) {
      this.showSubjectsField = true;
      this.showRegentsAdminField = true;
      this.subjectLabel = 'Subject';
    }
    if (categories.includes(supportCats.ATTENDANCE)) this.showSubcategoriesField = true;
    if (categories.includes(supportCats.PHYS_HEALTH_SERVICES)) this.showSubcategoriesField = true;
  }

  public calculateCategoryDependentFields (categories: ISchoolSupport['categories']) {
    const selectedCats = categories.map(cat => cat.category);
    this.subjects = this.sms.getDynamicSubjects(selectedCats, this.schoolType);
    this.subcats = this.sms.getDynamicSubcategories(selectedCats, this.subcatBuckets);
    this.setInputVisibility(selectedCats);

    if (this.subcats) {
      return reduce(
        categories,
        (res: ISchoolSupportMetadata, cat) => {
          switch (cat.category) {
            case supportCats.REGENTS_PREP: {
              // cat.metaData.regentsAdminDate is not required, so form will validate w/o.
              if (cat.metaData && cat.metaData.examSubject) {
                return this.loadCatRegentsState(cat, res);
              } else {
                res.examSubject = ''; // If exam subject is empty on load, will be a legacy support which won't have this. Retain the empty value.
                res.regentsAdminDate = '';
                return res;
              }
            }
            case supportCats.ACADEMIC: {
              if (cat.metaData) {
                return this.loadCatAcademicState(cat, res);
              } else {
                res.subject = '';
                res.subcat = '';
                return res;
              }
            }
            case supportCats.ATTENDANCE: {
              if (cat.metaData) {
                return this.loadCatAttendanceState(cat, res);
              } else {
                res.subject = null;
                res.subcat = null;
                return res;
              }
            }
            case supportCats.PHYS_HEALTH_SERVICES: {
              if (cat.metaData && cat.metaData.serviceName) {
                return this.loadCatPhysHealthServicesState(cat, res);
              } else res.subcat = null;
              return res;
            }
            default:
              res.subject = null;
              res.serviceName = null;
              res.examSubject = null;
              res.regentsAdminDate = null;
              return res;
          }
        },
        {},
      );
    }
  }

  private loadCatPhysHealthServicesState (cat: any, res: ISchoolSupportMetadata) {
    const { serviceName } = cat.metaData;
    if (serviceName) {
      const sn = serviceName === 'Other' ? 'Health - Other' : serviceName;
      this.selectedSubcats.push(sn);
      this.currentPhysSelections.push(sn);
    }
    res.subcat = this.selectedSubcats;
    return res;
  }

  private loadCatAttendanceState (cat: any, res: ISchoolSupportMetadata) {
    const { serviceName } = cat.metaData;
    if (serviceName) {
      this.selectedSubcats.push(serviceName);
      this.otherSelections.push(serviceName);
    }
    res.subject = null;
    res.subcat = this.selectedSubcats;
    return res;
  }

  private loadCatAcademicState (cat: any, res: ISchoolSupportMetadata) {
    const { subject, serviceName } = cat.metaData;
    this.selectedSubject = subject || null;
    if (serviceName) {
      const sn = serviceName === 'Other' ? 'Academic - Other' : serviceName;
      this.selectedSubcats.push(sn);
      this.currentAcademicSelections.push(sn);
    }
    res.subject = subject;
    res.subcat = serviceName;
    return res;
  }

  private loadCatRegentsState (cat: any, res: ISchoolSupportMetadata) {
    const { examSubject, regentsAdminDate } = cat.metaData;
    const regExamSubj = filter(RegentsSubject, item => {
      return item.key === examSubject;
    })[0];
    this.selectedSubject = regExamSubj.longName ? regExamSubj.longName : null;
    this.regentsAdminDate = regentsAdminDate || '';
    res.examSubject = regExamSubj.longName;
    res.regentsAdminDate = regentsAdminDate || '';
    return res;
  }

  public loadSupport (users: any[], data: Partial<ISchoolSupport>): any {
    const {
      name,
      description,
      tier,
      categories,
      location,
      activityLeads,
      terms,
      status,
      schedule: { repeats, repeatsOn, repeatsEvery, startsOn, endsOn, startTime, endTime },
    } = data;

    const nameForMode = this.mode === 'DUPLICATE' ? `Copy of ${name}` : name;
    this.loadedCats = this.setLoadedCats(categories);
    this.selectedTier = this.tiers.find( picker => picker.key === tier) || 
      this.tierToPicker(Tiers.UNSPECIFIED);
    this.initialTier = this.selectedTier;
    const categoryDependentFields = this.calculateCategoryDependentFields(categories);

    this.showDateTimePickers = !!repeats;

    this.setSelectedRepeatsAndFreq({ repeats, repeatsOn });

    if (!this.frequencyIsCustom) {
      const week = this.sms.getWeek(repeatsOn);
      this.weekdays = values(week);
    } ;

    const authorizedLeads = activityLeads.filter(({ userId }) => users.some(({ id }) => id === userId));
    each(authorizedLeads, lead => {
      const nameAsKey = `${lead.firstName} ${lead.lastName}`;
      this.selectedActivityLeads.push(nameAsKey);
    });

    this.selectedRepeatsEvery = this.repeatsEveryMapping[repeatsEvery] ?? null;

    this.isCompleted = status === SupportStatuses.backend.COMPLETED;
    const isDuplicateMode = this.mode === 'DUPLICATE';
    this.disableDateChange = this.isCompleted && !isDuplicateMode; // disable editing start/end for completed supports

    let startsOnByTerm;
    let endsOnByTerm;
    if(isDuplicateMode) {
      startsOnByTerm = (this.currentTerm as any).termStartDate;
      endsOnByTerm = (this.currentTerm as any).termEndDate;
    }

    const startDate = startsOnByTerm || startsOn ? moment.utc(startsOnByTerm || startsOn).format('YYYY-MM-DD') : null;

    const endDate = endsOnByTerm || endsOn ? moment.utc(endsOnByTerm || endsOn).format('YYYY-MM-DD') : null;

    return {
      nameForMode,
      description,
      tier,
      loadedCats: this.loadedCats,
      categoryDependentFields,
      activityLeads: authorizedLeads,
      terms,
      repeats,
      repeatsOn,
      repeatsEvery,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
    };
  }

  public duplicateNameValidator (control: AbstractControl) {
    const nameAlreadyExists = this.names.includes(control.value);
    if (!this.origName && control.pristine) {
      this.origName = control.value;
    } else {
      if (this.origName !== control.value) {
        if (nameAlreadyExists) {
          return {
            invalid: false,
          };
        } else {
          return null;
        }
      }
    }
  }

  private setSelectedRepeatsAndFreq ({ repeats, repeatsOn }: {repeats: TValidSchoolSupportRepeatsOptions | undefined, repeatsOn: IDaysOfTheWeekBoolean | undefined}): void {
    const { selectedRepeats, frequencyIsOnce } = this.sms.getRepeatsStringAndFreq({
      repeats,
      repeatsOn,
    });
    this.selectedRepeats = selectedRepeats;
    this.frequencyIsOnce = frequencyIsOnce;
    this.frequencyIsCustom = selectedRepeats === 'Custom';
  };

  private setSelectedTerms ({ startTerm, endTerm }: { startTerm?: ITermInfoMini, endTerm?: ITermInfoMini }) : void {
    this.selectedTerms = this.sms.getSelectedTerms({ startTerm, endTerm, mode: this.mode, currentTerm: this.currentTerm, termPickerOptionsMap: this.termPickerOptionsMap });

    const keyOfStartTerm = this.selectedTerms?.startTerm?.option?.key;
    let endOptionIndex = 1;
    if (keyOfStartTerm) {
      endOptionIndex = this.termPickerOptionsMap.get(keyOfStartTerm).index + 1;
    }
    this.termPickerEndOptions = this.termPickerStartOptions.slice(
      0,
      endOptionIndex || 1,
    );
  }

  public buildForm (users: any[], data?: Partial<ISchoolSupport>) {
    let initForm: any = {};

    if (data) {
      const {
        nameForMode,
        description,
        tier,
        loadedCats,
        categoryDependentFields,
        activityLeads,
        terms,
        repeats,
        repeatsOn,
        repeatsEvery,
        location,
        startDate,
        endDate,
        startTime,
        endTime,
      } = this.loadSupport(users, data);

      const { startTerm, endTerm, termRange } = terms;
      this.termYearArray = termRange;

      this.setSelectedTerms({ startTerm, endTerm });

      initForm = {
        name: nameForMode,
        description,
        tier,
        categories: loadedCats,
        subject: categoryDependentFields.subject
          ? categoryDependentFields.subject
          : categoryDependentFields.examSubject,
        subcat: categoryDependentFields.subcat,
        regentsAdminDate: categoryDependentFields.regentsAdminDate,
        location,
        activityLeads,
        repeats,
        repeatsOn,
        repeatsEvery,
        date: startDate || this.selectedTerms.startTerm.termStartDate,
        dateRange: {
          startDate: startDate || this.selectedTerms.startTerm.termStartDate,
          endDate: endDate || this.selectedTerms.endTerm.termEndDate,
        },
        timeRange: { startTime, endTime },
      };
    } else {
      this.selectedTerms = {
        startTerm: { ...this.currentTerm },
        endTerm: { ...this.currentTerm },
      };
      initForm = {
        name: null,
        description: '',
        tier: undefined,
        categories: null,
        subject: '',
        subcat: '',
        regentsAdminDate: null,
        activityLeads: null,
        repeats: null,
        repeatsEvery: 1,
        repeatsOn: cloneDeep(SUPPORT_SCHEDULE_WEEK_OBJ),
        date: this.selectedTerms.startTerm.termStartDate,
        dateRange: {
          startDate: this.selectedTerms.startTerm.termStartDate,
          endDate: this.selectedTerms.endTerm.termEndDate,
        },
        timeRange: {
          startTime: null,
          endTime: null,
        },
      };
    }

    const set1 = new FormGroup({
      name: new FormControl(initForm.name, [
        Validators.required,
        Validators.maxLength(this.maxCharacters),
        this.duplicateNameValidator.bind(this),
      ]),
      description: new FormControl(initForm.description, [Validators.maxLength(this.maxCharacters)]),
      categories: new FormControl(initForm.categories, [Validators.required]),
      // subject validation is based on category selection
      subject: new FormControl(initForm.subject, []),
      subcat: new FormControl(initForm.subcat, []),
      regentsAdminDate: new FormControl(null, []),
    });

    const expectedDateRegex = this.regexUtil.expectedYyyyMmDdRegex();

    this.dateRangeForm = new FormGroup({
      startDate: new FormControl(initForm.dateRange.startDate, [Validators.pattern(expectedDateRegex)]),
      endDate: new FormControl(initForm.dateRange.endDate, [Validators.pattern(expectedDateRegex)]),
    });

    this.timeRangeForm = new FormGroup({
      startTime: new FormControl(initForm.timeRange.startTime, []),
      endTime: new FormControl(initForm.timeRange.endTime, []),
    });

    this.termRangeForm = new FormGroup({
      startTerm: new FormControl(this.selectedTerms.startTerm.option.key, []),
      endTerm: new FormControl(this.selectedTerms.endTerm.option.key, []),
    });

    const set2 = new FormGroup({
      activityLeads: new FormControl(initForm.activityLeads, [Validators.required]),
      repeats: new FormControl(initForm.repeats, []),
      repeatsEvery: new FormControl(initForm.repeatsEvery, []),
      repeatsOn: new FormControl(initForm.repeatsOn, []),
      termRangeForm: this.termRangeForm,
      date: new FormControl(initForm.date, []),
      dateRange: this.dateRangeForm,
      timeRangeForm: this.timeRangeForm,
      location: new FormControl(initForm.location, [Validators.maxLength(this.maxCharacters)]),
    });
    return this.formBuilder.group({ set1, set2 });
  }

  private setFormSubscriptions (): void {
    this.formSubscription = this.supportForm.controls.set1.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
      )
      .subscribe(changes => {
        let changed = false;
        // tslint:disable-next-line: forin
        for (const prop in changes) {
          if (isEqual(changes[prop], (this.originalForm as any).set1[prop])) {
            (this.supportForm.get('set1') as any).controls[prop].markAsPristine();
          } else {
            (this.supportForm.get('set1') as any).controls[prop].markAsDirty();
            changed = true;
          }
        }
        if (changed) {
          this.supportForm.controls.set1.markAsDirty();
        }
      });

    this.formSubscription2 = this.supportForm.controls.set2.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
      )
      .subscribe(changes => {
        let changed = false;
        // tslint:disable-next-line: forin
        for (const prop in changes) {
          if (isEqual(changes[prop], (this.originalForm as any).set2[prop])) {
            (this.supportForm.get('set2') as any).controls[prop].markAsPristine();
          } else {
            if (prop === 'timeRangeForm' || prop === 'dateRange') {
              each((this.supportForm.get('set2') as any).controls[prop].controls, ctrl => {
                ctrl.markAsDirty();
              });
            } else {
              (this.supportForm.get('set2') as any).controls[prop].markAsDirty();
            }
            changed = true;
          }
        }
        if (changed) {
          this.supportForm.controls.set2.markAsDirty();
        }
      });
  }

  clearField (set, field: string): void {
    (this.supportForm.get(set) as any).controls[field].patchValue(null);
  }

  clearCatDependentSelections (): void {
    const regentsAdminCtrl = (this.supportForm.get('set1') as FormGroup).controls.regentsAdminDate;
    this.selectedCats = [];
    this.selectedSubject = '';
    this.selectedSubcats = [];
    this.otherSelections = [];
    this.currentAcademicSelections = [];
    this.currentPhysSelections = [];
    this.showSubcategoriesField = false;
    this.showRegentsAdminField = false;
    this.showSubjectsField = false;
    this.selectedCats = [];
    (this.supportForm.get('set1') as any).controls.categories.setErrors(true);
    regentsAdminCtrl.clearValidators();
    regentsAdminCtrl.setValue(null);
    regentsAdminCtrl.updateValueAndValidity();
    this.cd.detectChanges();
  }

  doSelectedCatsHaveRequiredMeta (cats): boolean {
    const required = cat => this.catsWithRequiredMeta[cat];
    return cats.some(required);
  }

  public selectTier(key: string) : void {
    this.selectedTier = this.tiers.find( tier => tier.key === key);
  }

  public handleSelectedCats (selectedCats): void {
    const countSel = selectedCats.length;
    if (countSel === 0) {
      this.clearCatDependentSelections();
    } else {
      // get menus
      this.subjects = this.sms.getDynamicSubjects(selectedCats, this.schoolType);
      this.subcats = this.sms.getDynamicSubcategories(selectedCats, this.subcatBuckets);

      const {
        categories: catCtrl,
        subject: subjectCtrl,
        subcat: subcatCtrl,
        regentsAdminDate: regentsAdminCtrl,
      } = (this.supportForm.get('set1') as any).controls;

      this.setInputVisibility(selectedCats);

      // set input validity
      const atLeastOneCatHasMeta = selectedCats.some(selectedCat => this.catsWithMeta.indexOf(selectedCat) > -1);
      const atLeastOneCatHasRequiredMeta =
        atLeastOneCatHasMeta && this.doSelectedCatsHaveRequiredMeta(selectedCats);

      if (atLeastOneCatHasMeta) {
        if (atLeastOneCatHasRequiredMeta) {
          regentsAdminCtrl.setValidators([Validators.required]);
          regentsAdminCtrl.updateValueAndValidity();
          subjectCtrl.setErrors(true);
          if (subjectCtrl.value) {
            subjectCtrl.setErrors(true);
            this.selectedSubject = '';
          }
          this.showRegentsAdminField = true;

          if (selectedCats.length > 1) {
            const currentSelectionHasCatsWithSubjects = selectedCats.some(selectedCat => {
              return catsWithMetaService.includes(selectedCat);
            });
            this.showSubcategoriesField = !!currentSelectionHasCatsWithSubjects;
          } else this.showSubcategoriesField = false;
        } else if (!atLeastOneCatHasRequiredMeta) {
          subjectCtrl.setErrors(null);
          subjectCtrl.setValue(null);
          this.selectedSubject = '';
          this.showSubcategoriesField = true;
          this.showRegentsAdminField = false;
        }
      } else if (!atLeastOneCatHasMeta) {
        this.showSubjectsField = false;
        this.showSubcategoriesField = false;
        this.showRegentsAdminField = false;
        subjectCtrl.setErrors(null);
      }

      const catsForPayload = this.loadedCats
        ? this.setCatsForPayload(this.loadedCats, this.categories)
        : this.categories;
      const updatedCatCtrlValue = reduce(
        catsForPayload,
        (res, catObj) => {
          if (selectedCats.includes(catObj.key)) res.push(catObj);
          return res;
        },
        [],
      );
      const updatedSubcatCtrlValue = reduce(
        updatedCatCtrlValue,
        (res, cat) => {
          const path = cat.metaData && Object.keys(cat.metaData).length ? Object.values(cat.metaData)[0] : null;
          if (path) res.push({ key: path, human: path });
          return res;
        },
        [],
      );
      catCtrl.setValue(updatedCatCtrlValue);
      catCtrl.updateValueAndValidity();
      subcatCtrl.setValue(null);
      this.selectedSubcats = compact(
        map(updatedCatCtrlValue, updatedCat => {
          if (updatedCat.metaData) {
            let sub = updatedCat.metaData.serviceName;
            if (updatedCat.key === supportCats.ACADEMIC && sub === 'Other') {
              sub = 'Academic - Other';
            }
            if (updatedCat.key === supportCats.PHYS_HEALTH_SERVICES && sub === 'Other') {
              sub = 'Health - Other';
            }
            return sub;
          }
        }),
      );
      subcatCtrl.setValue(updatedSubcatCtrlValue);
      subcatCtrl.updateValueAndValidity();
      this.cd.detectChanges();
    }
  }

  private setCatsForPayload (loadedSupportCats, defaultCats) {
    return reduce(
      defaultCats,
      (res, defaultCat: any) => {
        const updatedCat = find(loadedSupportCats, loadedCat => defaultCat.key === loadedCat.key);
        if (updatedCat) res.push(updatedCat);
        else res.push(defaultCat);
        return res;
      },
      [],
    );
  }

  public handleSelectedSubject (subject: any): void {
    this.selectedSubject = subject;
    const { categories: catCtrl, subject: subjectCtrl } = (this.supportForm.get('set1') as any).controls;
    subjectCtrl.setValue(subject);

    const hasRegentsPrep = this.selectedCats.includes(supportCats.REGENTS_PREP);
    const hasAcademic = this.selectedCats.includes(supportCats.ACADEMIC);

    const subjects = { acad: null, reg: null };
    if (hasRegentsPrep && hasAcademic) {
      const { supportSubjectKey, regentsSubjectKey } = filter(subjectsForRegentsAndAcademicCatPatches, (cat: any) => {
        return cat.display === subject;
      })[0];
      subjects.acad = supportSubjectKey;
      subjects.reg = regentsSubjectKey;
    } else {
      if (hasRegentsPrep) {
        const regSub = filter(RegentsSubject, (regSub: any) => {
          return regSub.longName === this.selectedSubject;
        })[0];
        subjects.reg = regSub.key;
      }
      if (hasAcademic) subjects.acad = this.selectedSubject;
    }

    const categoriesWithSubjectUpdates = filter(catCtrl.value, catObj => {
      switch (catObj.key) {
        case supportCats.ACADEMIC:
          if (!catObj.metaData) catObj.metaData = {};
          catObj.metaData.subject = subjects.acad;
          return catObj;
        case supportCats.REGENTS_PREP:
          if (!catObj.metaData) catObj.metaData = {};
          catObj.metaData.examSubject = subjects.reg;
          return catObj;
        default:
          return catObj;
      }
    });
    const updatedCatCtrlValue = reduce(
      catCtrl.value,
      (res, cat, i) => {
        find(categoriesWithSubjectUpdates, catWithUpdate => {
          if (cat.key === catWithUpdate.key) cat.metaData = catWithUpdate.metaData;
        });
        res[i] = cat;
        return res;
      },
      [],
    );
    catCtrl.setValue(updatedCatCtrlValue);
  }

  public handleSubcatClicked (evt: IOptionClickedEvent): void {
    const selectedSubcat: any = find(this.subcats, cat => {
      return cat.human === evt.value;
    });
    const subcatCtrl = (this.supportForm.get('set1') as any).controls.subcat;
    const catCtrl = (this.supportForm.get('set1') as any).controls.categories;

    if (evt.value === 'clear' || this.selectedSubcats.length === 0) {
      this.currentAcademicSelections = [];
      this.currentPhysSelections = [];
      this.otherSelections = [];
      const updateCatCtrlValue = map(catCtrl.value, cat => {
        cat.metaData = {};
        return cat;
      });
      subcatCtrl.setValue(null);
      subcatCtrl.updateValueAndValidity();
      catCtrl.setValue(updateCatCtrlValue);
      catCtrl.updateValueAndValidity();
    } else {
      const isDeletion = !this.selectedSubcats.includes(selectedSubcat.key);
      const isOtherKey = selectedSubcat.key === 'Other';

      if (ACADEMIC_SUBCATS.includes(selectedSubcat.human)) {
        const included = this.currentAcademicSelections.includes(selectedSubcat.key);
        this.currentAcademicSelections = included ? [] : [selectedSubcat.key];
        if (included && isOtherKey) {
          const index = this.currentAcademicSelections.indexOf('Other');
          this.currentAcademicSelections[index] = 'Academic - Other';
        }
      } else if (PHYS_HEALTH_SUBCATS.includes(selectedSubcat.human)) {
        const included = this.currentPhysSelections.includes(selectedSubcat.key);
        this.currentPhysSelections = included ? [] : [selectedSubcat.key];
        if (included && isOtherKey) {
          const index = this.currentPhysSelections.indexOf('Other');
          this.currentPhysSelections[index] = 'Health - Other';
        }
      } else {
        const index = this.otherSelections.indexOf(selectedSubcat.key);
        if (index > -1) this.otherSelections.splice(index, 1);
        else this.otherSelections.push(selectedSubcat.key);
      }

      // reassemble
      const spread = [...this.currentAcademicSelections, ...this.currentPhysSelections, ...this.otherSelections];
      this.selectedSubcats = uniq(spread);
      const subcatUpdatesToMap = isDeletion ? [selectedSubcat.key, ...spread] : spread;
      const subcatMap = reduce(
        subcatUpdatesToMap,
        (res, item) => {
          let validSubcat = item;
          if (item === 'Academic - Other' || item === 'Health - Other') {
            validSubcat = 'Other';
          }
          res.payload.push({ key: validSubcat, human: validSubcat });
          res.searchable.push({ key: item, human: item });
          return res;
        },
        { payload: [], searchable: [] },
      );

      const catCtrlUpdates = map(subcatMap.searchable, (sm: any) => {
        const matchedCat = find(SupportCategories, supportCategory => {
          if (supportCategory.subOptions) return supportCategory.subOptions.some(e => e.value === sm.key);
        });
        let metaData;
        if (selectedSubcat.key === sm.key) {
          metaData = isDeletion ? { serviceName: null } : (metaData = { serviceName: sm.key });
        } else metaData = { serviceName: sm.key };
        return {
          key: matchedCat.key,
          metaData,
        };
      });

      const catCtrlUpdatesClean = map(catCtrlUpdates, cat => {
        if (cat.metaData.serviceName === 'Academic - Other' || cat.metaData.serviceName === 'Health - Other') {
          cat.metaData.serviceName = 'Other';
        }
        return cat;
      });

      const updatedCatCtrlValue = catCtrl.value.map(currentField => {
        find(catCtrlUpdatesClean, updatedCat => {
          if (currentField.key === updatedCat.key) { currentField.metaData = Object.assign({}, currentField.metaData, updatedCat.metaData); }
        });
        return currentField;
      });

      // The Subcat ctrl value do not map to their own prop on the payload, since they are already part of the categories on the schema
      const updatedSubcatCtrlValue = reduce(
        updatedCatCtrlValue,
        (res, cat) => {
          const path = cat.metaData ? cat.metaData.serviceName : null;
          if (path && path.length) res.push({ key: path, human: path });
          return res;
        },
        [],
      );
      subcatCtrl.setValue(updatedSubcatCtrlValue);
      subcatCtrl.updateValueAndValidity();
      catCtrl.setValue(updatedCatCtrlValue);
      catCtrl.updateValueAndValidity();
      this.cd.detectChanges();
    }
  }

  public handleSelectedRegentsAdminDate (date): void {
    const regentsAdminDateCtrl = (this.supportForm.get('set1') as FormGroup).controls.regentsAdminDate;
    regentsAdminDateCtrl.setValue(date);
    regentsAdminDateCtrl.updateValueAndValidity();
    this.regentsAdminDate = date;
    const regentsPrepCatObj = find((this.supportForm.get('set1') as any).controls.categories.value, catObj => {
      return catObj.key === supportCats.REGENTS_PREP;
    });
    regentsPrepCatObj.metaData.regentsAdminDate = this.regentsAdminDate;
  }

  /** ************************   FIELD SET 2  ***************/

  public handleSelectedActivityLeads (activityLeads): void {
    if (activityLeads.length === 0) {
      this.selectedActivityLeads = [];
    } else {
      (this.supportForm.get('set2') as any).controls.activityLeads.patchValue(activityLeads);
    }
  }

  clearScheduleValidators (controls): void {
    const scheduleControls = [controls.repeatsOn, controls.repeatsEvery, controls.dateRange, controls.dateRange.controls.startDate, controls.dateRange.controls.endDate, controls.date];
    scheduleControls.forEach(control => {
      if (control) {
        control.removeValidators([Validators.required]);
        control.updateValueAndValidity();
      }
    });
  }

  clearFrequencyDependentSelections (controls): void {
    this.sms.setFreqControlErrors(controls);
    this.showDateTimePickers = false;
    this.selectedRepeatsEvery = '';

    this.weekdays.forEach(day => {
      day.isActive = false;
    });
    this.clearScheduleValidators(controls);
  }

  setDateRangeFormRquired (dateRangeControls: any) {
    const startDateControls = dateRangeControls.controls.startDate;
    const endDateControls = dateRangeControls.controls.endDate;
    dateRangeControls.setValidators([Validators.required]);
    dateRangeControls.updateValueAndValidity();
    startDateControls.setValidators([Validators.required]);
    startDateControls.updateValueAndValidity();
    endDateControls.setValidators([Validators.required]);
    endDateControls.updateValueAndValidity();
  }

  public handleSelectedRepeats (freq: string): void {
    const { date, dateRange, repeats, repeatsOn, repeatsEvery, termRangeForm } = (this.supportForm.get('set2') as any).controls;
    this.selectedRepeats = freq;
    this.frequencyIsOnce = freq === 'One time';
    this.frequencyIsCustom = freq === 'Custom';
    this.clearFrequencyDependentSelections({ dateRange, repeatsOn, repeatsEvery, termRangeForm });

    repeatsEvery.setValidators([Validators.required]);
    repeatsOn.setValidators([Validators.required]);

    if (this.frequencyIsOnce) this.termRangeLabel = 'Term';
    else this.termRangeLabel = 'Start and end term';

    const schedule = this.sms.getScheduleFromFreq({
      repeatsOn,
      repeatsEvery,
      freq,
    });

    const week = this.sms.getWeek(repeatsOn.value);
    this.weekdays = week;

    if (this.frequencyIsCustom) {
      this.setDateRangeFormRquired(dateRange);
    } else if (this.frequencyIsOnce) {
      repeatsOn.patchValue(schedule.repeatsOn);
      repeatsEvery.patchValue(schedule.repeatsEvery);
      date.setValidators([Validators.required]);
      date.updateValueAndValidity();
    } else {
      repeatsOn.patchValue(schedule.repeatsOn);
      repeatsEvery.patchValue(schedule.repeatsEvery);
      this.setDateRangeFormRquired(dateRange);
    }

    this.showDateTimePickers = true;
    repeats.patchValue(schedule.repeats);
  }

  public onTermPickerSelect (optionKey: string, picker: 'start' | 'end'): void {
    const selectedTerm = this.termPickerOptionsMap.get(optionKey);

    if (picker === 'start') {
      // update dropdown
      this.selectedTerms = {
        startTerm: selectedTerm,
        endTerm: selectedTerm,
      };

      // update term picker form
      this.termRangeForm.patchValue({
        startTerm: this.selectedTerms.startTerm.option.key,
        endTerm: this.selectedTerms.endTerm.option.key,
      });

      // update date range form
      this.dateRangeForm.patchValue({
        startDate: selectedTerm.termStartDate,
        endDate: selectedTerm.termEndDate,
      });

      const { date } = (this.supportForm.get('set2') as any).controls;
      date.setValue(selectedTerm.termStartDate);

      // update end term dropdown options
      this.termPickerEndOptions = this.termPickerStartOptions.slice(0, selectedTerm.index + 1);
    } else {
      // only update end term and date pickers
      this.selectedTerms.endTerm = selectedTerm;

      // update term picker form
      this.termRangeForm.patchValue({
        endTerm: this.selectedTerms.endTerm.option.key,
      });

      // updat date range form
      this.dateRangeForm.patchValue({
        endDate: selectedTerm.termEndDate,
      });
    }

    // update term range
    const { startTerm, endTerm } = this.termRangeForm.value;
    const startIndex = this.termPickerOptionsMap.get(startTerm).index;
    const endIndex = this.termPickerOptionsMap.get(endTerm).index;
    const termRange = this.orderedTermDates.slice(endIndex, startIndex + 1);
    this.termYearArray = map(termRange, term => term.yearTerm);
  }

  public handleSelectedRepeatsEvery (opt: string): void {
    this.selectedRepeatsEvery = opt;
    const repeatsEvery = parseInt(opt.split(' ')[0]);
    (this.supportForm.get('set2') as any).controls.repeatsEvery.setValue(repeatsEvery);
  }

  public selectRepeatsOnDay (dayObj: any): any {
    const repeatsOnCtrl = (this.supportForm.get('set2') as any).controls.repeatsOn;
    dayObj.isActive = !dayObj.isActive;
    const payload = {};

    this.weekdays = this.sms.updateWeekdaysSelections(this.weekdays, dayObj);
    this.weekdays.forEach(day => {
      payload[day.validKey] = day.isActive;
    });

    const hasActiveDays = this.weekdays.some(day => day.isActive);
    if (!hasActiveDays) {
      repeatsOnCtrl.setErrors(true);
    } else {
      repeatsOnCtrl.setErrors(null);
      repeatsOnCtrl.setValue(payload);
    }
  }

  private getStudentIds (): Promise<string[]> {
    return this.sms
      .getStudentIdsForSupport$(this.schoolId, this.supportId).pipe(take(1))
      .toPromise();
  }

  private async setStudentIds (): Promise<void> {
    this.studentIds = await this.getStudentIds();
  }

  public toggleReassignStudents () {
    this.shouldReassignStudents = !this.shouldReassignStudents;
    if (!this.haveQueriedStudentIds) {
      this.haveQueriedStudentIds = true;
      this.setStudentIds();
    }
  }

  private createSupport (payload: ICreateSupportPayload): void {
    this.store.dispatch(new CreateSupport(payload));
  }

  // NAV
  public nextFieldSet (): void {
    if (this.supportForm.get('set1').invalid) return;
    this.childHasSecondPage = true;
    this.showFieldSetTwo();
  }

  public showFieldSetOne () {
    this.fieldSet1IsActive = true;
    this.fieldSet2IsActive = false;
    this.childHasSecondPage = false;
    this.resetTitle();
  }

  public showFieldSetTwo () {
    this.fieldSet2IsActive = true;
    this.supportName = this.data.support?.name || this.supportForm.value.set1.name;
    this.formVariant.title = this.supportName;
    const catsForSubtitle = [];
    this.supportForm.value.set1.categories.forEach((cat) => catsForSubtitle.push(cat.human));
    const tierForSub = (this.selectedTier && this.selectedTier.key !== '-') ? `Tier ${this.selectedTier?.key} ` : undefined;
    this.supportSubtitle = tierForSub ? `${tierForSub} ${catsForSubtitle.join(', ')} Support` : `${catsForSubtitle.join(', ')} Support`;
    this.fieldSet1IsActive = false;
  }

  public resetTitle () {
    const mode = this.data.mode;
    this.formVariant = this.sms.getFormVariants(mode);
    this.subtitle = null;
  }

  public setSupportPage2TitleAndSubtitle () {
    this.formVariant.title = this.supportName;
    const catsForSubtitle = [];
    this.supportForm.value.set1.categories.forEach((cat) => catsForSubtitle.push(cat.human));
    let tierForSub;
    if (this.selectedTier && this.selectedTier.key !== '-') tierForSub = `Tier ${this.selectedTier?.key}`;
    this.supportSubtitle = tierForSub ? `${tierForSub} ${catsForSubtitle.join(', ')} Support` : `${catsForSubtitle.join(', ')} Support`;
  }

  private getSchoolYearPatch (term: string) {
    const [,, schoolYearPatch] = term.split(' ');
    return schoolYearPatch;
  }

  public openReassignStuSupportsModal ({ startDate, endDate }: {startDate?: string, endDate?: string}): void {
    if (this.needsDateChangeConfirmation) {
      const data = {
        title: 'Confirm',
        message: this.sms.getConfirmDateChangeMessage({
          startDate,
          endDate,
          deleteOrReassign: 'reassignSupport',
        }),
        subMessage: 'Students who have already completed the support will not have their dates changed.',
        confirmText: 'Yes',
        cancelText: 'No',
      };
      this.modalsService.openConfirmModal(data)
        .afterClosed()
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.changeRelatedStudentSupports = true;
            this.reassignStuSupports({ startDate, endDate });
          }
        });
    }
  };

  public openAttRecordConfirmDeleteModal ({ startDate, endDate }: {startDate?: string, endDate?: string}) : void {
    if (this.needsAttRecordDelConfirmed) {
      const data = this.sms.getAttRecordData({ startDate, endDate });

      this.modalsService.openConfirmModal(data)
        .afterClosed()
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.attRecordDelConfirmed = true;
            this._trackSupportActionEvent(this.mode);
            this.dispatchPatchPayload();
            super.close(this.payload);
          } else {
            this.dateRangeForm.patchValue({
              startDate: this.initialStartDate,
              endDate: this.initialEndDate,
            });
            this.disableFormSubmit = false;
          }
        });
    }
  }

  public async reassignStuSupports ({ startDate, endDate }: { startDate?: string, endDate?: string}) {
    if (!startDate && !endDate) return;
    this.sms.getStudentSupportIds$(this.schoolId, this.supportId).pipe(
      take(1),
      tap((studentSupportIds: string[]) => {
        const payload: IStudentSupportUpdatePayload = {
          supportId: this.supportId,
          studentSupportIds,
          status: 'ACTIVE',
          extendStudentSupports: this.changeRelatedStudentSupports,
        };
        if (startDate) payload.startsOn = startDate;
        if (endDate) payload.endsOn = endDate;
        this.store.dispatch(new BulkUpdateStudentSupports(payload));
      }),
    ).subscribe();
  }

  private startEarlierOrEndLater ({ startDate, startDateChangeType, endDate, endDateChangeType }) {
    const reassignArgs: {startDate?: string, endDate?: string} = {};
    const moveTypeDict = {
      movedEarlier: startDate,
      movedLater: endDate,
    };
    reassignArgs.startDate = moveTypeDict[startDateChangeType] || null;
    reassignArgs.endDate = moveTypeDict[endDateChangeType] || null;
    this.openReassignStuSupportsModal(reassignArgs);
  }

  private startLaterOrEndEarlier ({ startDate, startDateChangeType, endDate, endDateChangeType }) {
    const confirmArgs: {startDate?: string, endDate?: string} = {};
    const wasStartDateChanged = !!startDate && (startDateChangeType !== 'noChange');
    this.needsAttRecordDelConfirmed = this.sms.haveRelevantDatesHappened({ originalStartDate: this.initialStartDate, startChanged: wasStartDateChanged, newEndDate: endDate });
    if (this.needsAttRecordDelConfirmed) {
      const moveTypeDict = {
        movedLater: startDate,
        movedEarlier: endDate,
      };
      confirmArgs.startDate = moveTypeDict[startDateChangeType] || null;
      confirmArgs.endDate = moveTypeDict[endDateChangeType] || null;
      this.openAttRecordConfirmDeleteModal(confirmArgs);
    } else {
      this._trackSupportActionEvent(this.mode);
      this.dispatchPatchPayload();
      super.close(this.payload);
    }
  }

  private dateChanges ({ startDate, endDate }:{startDate?: string, endDate?: string }): void {
    let startDateChangeType: TSupportDateChangeType;
    let endDateChangeType: TSupportDateChangeType;
    if (startDate) startDateChangeType = this.sms.checkDateChange(this.initialStartDate, startDate);
    if (endDate) endDateChangeType = this.sms.checkDateChange(this.initialEndDate, endDate);
    if (startDateChangeType === 'movedEarlier' ||
        endDateChangeType === 'movedLater') {
      this.startEarlierOrEndLater({
        startDate,
        startDateChangeType,
        endDate,
        endDateChangeType,
      });
    }
    if (startDateChangeType === 'movedLater' ||
      endDateChangeType === 'movedEarlier') {
      this.startLaterOrEndEarlier({
        startDate,
        startDateChangeType,
        endDate,
        endDateChangeType,
      });
    };
    if (
      this.mode === 'EDIT' &&
      !(this.data?.support?.schedule?.endsOn && this.data?.support?.schedule?.startsOn)
    ) {
      this.openReassignStuSupportsModal({ startDate, endDate });
    }
  }

  public openScheduleSelection () {
    const data = {
      schoolId: this.schoolId,
      supportId: this.supportId,
      modalOrigin: 'SUPPORT_MODAL',
      mode: this.mode,
      isCompleted: this.isCompleted,
      supportName: this.supportName,
    } as any;
    if (this.data.support?.schedule?.startsOn && this.data.support?.schedule?.endsOn) {
      data.supportSchedule = cloneDeep(this.data.support.schedule);
      data.terms = this.data.support?.terms;
    }
    this.modalsService.openSupportScheduleModal(data)
      .afterClosed()
      .subscribe(data => {
        if (data) {
        // TODO: add updating values when implementing as part of support modal redesign
        // TODO: remember that needsAttRecDelConfirmation and date change confirmation may need to be called
        }
      });
  }

  public dispatchPatchPayload () {
    this.store.dispatch(new UpdateSupport({ supportId: this.supportId, patch: this.payload, requiresMadlibRefresh: this.requiresMadlibRefresh } as any));
  }

  public async submit (): Promise<any> {
    this.disableFormSubmit = true;

    const { categories } = (this.supportForm.get('set1') as any).controls;
    const { activityLeads, repeats, date, dateRange, repeatsEvery, repeatsOn } = (this.supportForm.get('set2') as any).controls;

    const actLeadsPayload = this.sms.getValidActivityLeadsPayload(this.selectedActivityLeads, this.users);
    const categoriesPayload = this.sms.getValidCategoriesPayload(categories);
    activityLeads.setValue(actLeadsPayload);
    categories.setValue(categoriesPayload);

    const startDate = dateRange.get('startDate');
    const endDate = dateRange.get('endDate');

    if (repeats && repeats.value === 'NONE') {
      date.setValidators([Validators.required]);
      date.updateValueAndValidity();
      startDate.setValue(date.value);
      endDate.setValue(date.value);
    }

    if (!repeatsOn.valid && !repeatsEvery.valid) {
      repeatsOn.setErrors(true);
      repeatsEvery.setErrors(true);
    }

    this.supportForm.updateValueAndValidity();
    if (this.supportForm.valid) {
      const { categories, name, description } = (this.supportForm.get('set1') as any).controls;
      const {
        location,
        repeatsEvery,
        repeats,
        repeatsOn,
        activityLeads,
        date,
        timeRangeForm: {
          controls: { startTime, endTime },
        },
      } = (this.supportForm.get('set2') as any).controls;

      const { startTerm, endTerm } = this.termRangeForm.controls;
      const startTermMini = this.sms.getTermMini({ termYear: startTerm?.value, district: this.district, termPickerOptionsMap: this.termPickerOptionsMap });
      const endTermMini = this.sms.getTermMini({ termYear: endTerm?.value, district: this.district, termPickerOptionsMap: this.termPickerOptionsMap });

      const payload: ISupportOptPayload = {};
      payload._id = this.supportTemplate._id;
      const isTierSpecified = this.selectedTier.key !== Tiers.UNSPECIFIED.key;
      if (this.mode === 'EDIT') {
        if (name.value && name.dirty) payload.name = name.value;
        if (description.dirty) payload.description = description.value ? description.value : ' ';
        if (categories.dirty) payload.categories = categories.value;
        if (location.dirty) payload.location = location.value ? location.value : null;
        if (activityLeads.dirty) payload.activityLeads = activityLeads.value;
        if (this.initialTier !== this.selectedTier) {
          payload.tier = isTierSpecified ? this.selectedTier.key : null;
        }
        if (
          repeats.dirty ||
          repeatsEvery.dirty ||
          repeatsOn.dirty ||
          startDate.dirty ||
          endDate.dirty ||
          startTime.dirty ||
          endTime.dirty ||
          date.dirty
        ) {
          payload.schedule = {};
          if (repeats.value && repeats.dirty) payload.schedule.repeats = repeats.value;
          if (repeatsEvery.value && repeatsEvery.dirty) payload.schedule.repeatsEvery = repeatsEvery.value;
          if (repeatsOn.value && repeatsOn.dirty) payload.schedule.repeatsOn = repeatsOn.value;
          if (date.value && this.frequencyIsOnce) {
            payload.schedule.startsOn = date.value;
            payload.schedule.endsOn = date.value;
          }
          if (startDate.value && !this.frequencyIsOnce) {
            payload.schedule.startsOn = startDate.value;
            this.needsDateChangeConfirmation = true;
          }
          if (endDate.value && !this.frequencyIsOnce) {
            payload.schedule.endsOn = endDate.value;
            this.needsDateChangeConfirmation = true;
          }
          if (startTime.value && startTime.dirty) payload.schedule.startTime = startTime.value;
          if (endTime.value && endTime.dirty) payload.schedule.endTime = endTime.value;
        }
        payload.terms = {
          startTerm: startTermMini,
          endTerm: endTermMini,
          termRange: this.termYearArray,
        }
      } else {
        payload.schedule = {};
        payload.name = name.value;
        payload.description = description.value ? description.value : ' ';
        payload.tier = isTierSpecified ? this.selectedTier.key : null;
        payload.categories = categories.value;
        payload.location = location.value;
        payload.activityLeads = activityLeads.value;
        payload.schedule.repeats = repeats.value;
        payload.schedule.repeatsEvery = repeatsEvery.value;
        payload.schedule.repeatsOn = repeatsOn.value;
        payload.schedule.startsOn = this.showDateTimePickers ? startDate.value : null;
        payload.schedule.endsOn = this.showDateTimePickers ? endDate.value : null;
        payload.schedule.startTime = startTime?.value || null;
        payload.schedule.endTime = endTime?.value || null;
        payload.terms = {
          startTerm: startTermMini,
          endTerm: endTermMini,
          termRange: this.termYearArray,
        };
      }

      const requiresMadlibRefresh = !!activityLeads.dirty;

      const shouldReassignStudents = this.shouldReassignStudents && this.mode === 'DUPLICATE';

      if (shouldReassignStudents) {
        const studentIds = this.studentIds ? this.studentIds : await this.getStudentIds();
        this.createSupport({ schoolId: this.schoolId, supportParams: payload, requiresMadlibRefresh, studentIds });
      } else if (this.mode === 'CREATE' || this.mode === 'DUPLICATE') {
        this.createSupport({ schoolId: this.schoolId, supportParams: payload, requiresMadlibRefresh, view: this.data.view });
      } else if (this.mode === 'EDIT') {
        this.payload = payload;
        this.requiresMadlibRefresh = requiresMadlibRefresh;
        if (this.needsDateChangeConfirmation) {
          this.dateChanges({ startDate: startDate.value, endDate: endDate.value });
          if (this.needsAttRecordDelConfirmed) {
            return; // patch is dispatched after confirming date changes
          }
        }
        this.dispatchPatchPayload();
      }
      this._trackSupportActionEvent(this.mode);
      super.close(payload);
    }
  }

  private _trackSupportActionEvent (mode: TSupportModalMode): void {
    let action;
    switch (mode) {
      case ('DELETE'):
        action = SUPPORT_AND_STUDENT_SUPPORT_ACTIONS.DELETE_SUPPORT;
        break;
      case ('EDIT'):
        action = SUPPORT_AND_STUDENT_SUPPORT_ACTIONS.UPDATE_SUPPORT;
        break;
      case ('DUPLICATE'):
        action = SUPPORT_AND_STUDENT_SUPPORT_ACTIONS.DUPLICATE_SUPPORT;
        break;
      default:
        return;
    }
    this.sharedEventTrackers.trackSupportOrStudentSupportEvent({ pageName: this.data.view || 'SUPPORT-MODAL', action });
  }
}
