import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api-service/api-service';
import { IApi } from '../api-service/api-service.interface';
import { catchError, map, take } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

export type TAdminOption = {
  key: string;
  human: string;
};

export type TAdminToExams = {
  adminKey: string;
  adminHuman: string;
  exams: TAdminOption[];
};

export type RegentsExamAdminDetails = {
  adminOptions: TAdminOption[];
  adminToExams: TAdminToExams[];
};

@Injectable()
export class RegentsDetailsService {
  private regentsExamsAdminDetails: RegentsExamAdminDetails;

  constructor (
    private apiService: ApiService,
  ) {};

  public getRegentsDetails$ (schoolId: string): Observable<RegentsExamAdminDetails> {
    if (this.regentsExamsAdminDetails) return of(this.regentsExamsAdminDetails); // Check for cached Regents details data

    const query = `{
      RegentsExamDetails (
        schoolId: "${schoolId}",
      ) {
        adminOptions {
          key
          human
        }
        adminToExams {
          adminKey
          adminHuman
          exams {
            key
            human
          }
        }
      }
    }`;
    const payload = { query, fetchPolicy: 'no-cache' };

    return this.apiService.getStudentsGraphQL(payload).pipe(
      take(1),
      map((res: IApi['GetRegentsDetailsRes']) => {
        const regentDetails: RegentsExamAdminDetails = res?.data.RegentsExamDetails;
        this.regentsExamsAdminDetails = regentDetails; // cached Regents details data
        return regentDetails;
      }),
      catchError(err => {
        return throwError(() => new Error(err));
      }),
    );
  };

}
