<div class="list-container" *ngIf="(this.groupings$ | async) as groupings; else noData">
  <list class="student-level student-level-sticky-header">
    <section #listStickyHeader class="section-list-header section-sticky-header">
      <listhead
        #fixedHeader
        class="s1"
        [ngClass]="{ 'batch-edit-mode': batchActionsMode$ | async, shadow: showShadow, hide: !nonEmptyTables.length }"
      >
        <cell>
          <div *ngIf="(batchActionsMode$ | async)">
            <mat-checkbox
              (click)="emitBatchActionStickyHeaderIds()"
              [checked]="stickyHeaderIsChecked$ | async"
              [indeterminate]="stickyHeaderIsInd$ | async"
              class="nv-mat-checkbox"
              disableRipple
              [disabled]="!displayedHeaders[0].total"
            ></mat-checkbox>
          </div>
        </cell>
        <ng-container *ngFor="let header of displayedHeaders; first as isFirst">
          <ng-container *ngIf="isFirst && !showFirstColumnHeader">
            <cell class="first" (click)="updateSort(header.key, sortKey$, sortDirection$, columns)">
              <span
                class="group-header"
                (mouseenter)="checkHeaderOverflow('group-header')"
                (mouseleave)="resetHeaderOverflow()"
                nvTooltip
                [tooltipData]="headerOverflow ? header.tooltip || header.human : header.tooltip"
              >
                {{ header.human }}
              </span>
              <ng-container>
                <pill *ngIf="!showRowsSelectedOfTotal" class="invert">{{ header.total }}</pill>
                <pill *ngIf="showRowsSelectedOfTotal" class="invert"
                  >{{ numRowsSelectedOfTotal }} of {{ header.total }}</pill
                >
                <ng-container *ngIf="sortableColumns">
                  <span
                    class="sort-arrow"
                    [ngClass]="{
                      'fixed-up': sortKey$.value === header.key && sortDirection$.value === 'asc',
                      'fixed-down': sortKey$.value === header.key && sortDirection$.value === 'desc'
                    }"
                  ></span>
                </ng-container>
              </ng-container>
            </cell>
          </ng-container>
          <ng-container *ngIf="!isFirst || showFirstColumnHeader">
            <cell class="center" (click)="updateSort(header.key, sortKey$, sortDirection$)">
              <div class="cell-sleeve">
                <span></span> <span nvTooltip [tooltipData]="header.tooltip">{{ header.human }} </span>
                <span
                  class="sort-arrow"
                  [ngClass]="{
                    'fixed-up': sortKey$.value === header.key && sortDirection$.value === 'asc',
                    'fixed-down': sortKey$.value === header.key && sortDirection$.value === 'desc'
                  }"
                ></span>
              </div>
            </cell>
          </ng-container>
        </ng-container>
      </listhead>
    </section>

    <div class="scroll-wrapper" #scrollWrapper stickyScroll (onStickyScrolled)="checkShadowAndStickyHeader($event)">
      <ng-container *ngFor="let group of groupings; index as groupIndx; trackBy: trackByGroupFn.bind(this)">
        <fixed-table
          [schoolId]="schoolId"
          [contextPartnerType]="contextPartnerType"
          [contextPartnerId]="contextPartnerId"
          [madlibModel]="madlibModel"
          [batchActionsMode$]="batchActionsMode$"
          [batchActionsSelectedIds$]="batchActionsSelectedIds$"
          [hideHeader]="!!nonEmptyTables.length && groupIndx === nonEmptyTables[0].indx"
          [columns]="columns"
          [displayedHeaders]="displayedHeaders"
          [columnIndexMap]="columnIndexMap"
          [groupData]="group"
          [maximumVisibleRowsPerGroup]="maximumVisibleRowsPerGroup"
          [filterFormControl]="filterFormControl"
          [currentFilterFormValue]="currentFilterFormValue"
          [sortKey$]="sortKey$"
          [sortDirection$]="sortDirection$"
          [groupIndx]="groupIndx"
          [listConfig]="listConfig"
          [dynamicComponentTrigger]="dynamicComponentTrigger"
          [showRowsSelectedOfTotal]="showRowsSelectedOfTotal"
          [showFirstColumnHeader]="showFirstColumnHeader"
          (uiRowData)="updateUiRowDataModel($event, uiRowDataModel)"
          (sortColUpdate)="updateSort($event, sortKey$, sortDirection$)"
          (focusedGroup)="loadFocusedGroup($event)"
          (clickedRowData)="emitStateChange($event)"
          (batchActionData)="emitBatchActionData($event)"
          (tableHeaderIsChecked)="checkedBatchUpdateHeaders.updateCheckedTableHeader($event)"
          (onDynamicComponentClick)="emitDynamicComponentClicked($event)"
          [dynamicComponentInputData]="dynamicComponentInputData"
        >
        </fixed-table>
      </ng-container>
      <ng-container *ngIf="!nonEmptyTables.length">
        <div *ngIf="listConfig.emptyState; else simpleMessage">
          <empty-state
            [emptyStateData]="listConfig.emptyState.data"
            (emptyStateButtonClick)="listConfig.emptyState.onButtonClick()">
          </empty-state>
        </div>
        <ng-template #simpleMessage>
          <list class="empty" [ngClass]="{ 'empty-sticky': !nonEmptyTables.length }">{{
            noDataMessage
          }}</list>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="(filterControl$ | async)">
        <div class="nv-button-clear" [ngClass]="{ 'button-clear-sticky': !nonEmptyTables.length }">
          <nv-button (clickButton)="onClear()">Clear search</nv-button>
        </div>
      </ng-container>
    </div>
  </list>
</div>
<ng-template #noData>
  <div *ngIf="listConfig.emptyState; else simpleMessage">
    <empty-state
      [emptyStateData]="listConfig.emptyState.data"
      (emptyStateButtonClick)="listConfig.emptyState.onButtonClick()">
    </empty-state>
  </div>
  <ng-template #simpleMessage>
    <list class="empty" [ngClass]="{ 'empty-sticky': !nonEmptyTables || !nonEmptyTables.length }">{{
      noDataMessage
    }}</list>
  </ng-template>
</ng-template>
