import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ITileClick, ITileVizData } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { skeletonVizMidsizeTheme } from '../../../../ng2/shared/constants/skeleton-loader.constant';

export type TValidVizTileTypes = 'bar' | 'trend';

@Component({
  selector: 'nv-viz-tile',
  templateUrl: './nv-viz-tile.component.html',
  styleUrls: ['./nv-viz-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvVizTileComponent {
  @Input() metricName: string;
  @Input() vizData: ITileVizData;
  @Input() tileKey: string;
  @Input() focusKey: string;
  @Input() vizType?: TValidVizTileTypes = 'bar';
  @Input() contentLoaded: boolean;
  @Output() tileClickEmitter = new EventEmitter<ITileClick>();
  @Output() clickedBar = new EventEmitter<any>();

  public skeletonVizTheme = skeletonVizMidsizeTheme;
  public navClass: string;
  private nonClickableTiles = ['ATTD_VIZ', 'ATTD_VIZ_SHELTER', 'SHELTER_YTD_ATTD_VIZ', 'COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ'];
  private barClickTiles = ['COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ'];

  getNavClass (): string {
    if (this.nonClickableTiles.includes(this.tileKey)) this.navClass = 'no-nav';
    return this.navClass;
  }

  emitTileClick (): void {
    const tileInfo = { metricName: this.metricName, focusKey: this.focusKey };
    const disableTileClick = this.nonClickableTiles.includes(this.tileKey);
    if (!disableTileClick) this.tileClickEmitter.emit(tileInfo);
  }

  onBarClick ($barGroup): void {
    const tileInfo = { metricName: this.metricName, focusKey: this.focusKey, barGroup: $barGroup };
    const enableBarClick = this.barClickTiles.includes(this.tileKey);
    if (enableBarClick) this.clickedBar.emit(tileInfo);
  }
}
