import { ImUser } from './../../services/im-models/im-user';
import { SupportStatuses } from './../../constants/support-statuses.constant';
import { IStudentSupport } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { LoadStudentSupports } from './../../../store/actions/student-supports-actions';
import { getStudentSupportsLoadedStatus, getStudentSupportsEntitiesList } from './../../../store/selectors/student-supports-selectors';
import { LoadSupports } from './../../../store/actions/supports-actions';
import { getSupportsEntitiesList, getSupportsLoadedStatus } from './../../../store/selectors/supports-selectors';
import { map as rxMap } from 'rxjs/operators';
import { getSchool } from './../../../store/selectors/school-selectors';
import { Observable } from 'rxjs';
import { getUsersEntities, getUsersLoadedStatus } from './../../../store/selectors/users-selectors';
import { StoreDataService } from './../../services/store-data-service/store-data.service';
import { SupportCategories } from './../../constants/support-categories.constant';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { SUPPORT_SCHEDULE_REPEAT_FREQUENCY_FRONTEND, SUPPORT_SCHEDULE_WEEK_OBJ, subjectsForRegentsAndAcademicCatPatches } from './support-modal.config';
import { sortBy, uniq, map, reduce, flatten, size, values, compact } from 'lodash';
import { RegentsSubject } from './../../constants/regents.constant';
import { supportSubjectOptionsBySchool } from './support-subject-options-by-school.constant';
import { Injectable } from '@angular/core';
import { ISchool, isElementaryOnly, ITermInfoMini, TValidSchoolTypes } from '../../typings/interfaces/school.interface';
import { IUserMini } from '../../typings/interfaces/user.interface';
import { LoadUsers } from 'Src/ng2/store/actions';
import { TSupportModalMode } from './support-modal.interface';
import moment from 'moment';
import { DateHelpers } from 'projects/shared/services/date-helpers/date-helpers.service';
import { IACOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { IDaysOfTheWeekBoolean, TValidSchoolSupportRepeatsOptions } from '../../typings/interfaces/support.interface';
import { TDistricts } from '../../typings/interfaces/district.interface';
import { getSchoolYearByDistrict } from '../../constants/current-school-year.constant';

const supportCats = {
  REGENTS_PREP: 'REGENTS_PREP',
  ATTENDANCE: 'ATTENDANCE',
  ACADEMIC: 'ACADEMIC',
  PHYS_HEALTH_SERVICES: 'PHYS_HEALTH_SERVICES',
};

const catsWithMeta = [
  supportCats.REGENTS_PREP,
  supportCats.ACADEMIC,
  supportCats.PHYS_HEALTH_SERVICES,
  supportCats.ATTENDANCE,
];

export const SUPPORT_WEEK = {
  monday: {
    human: 'Mon',
    isActive: false,
    validKey: 'monday',
  },
  tuesday: {
    human: 'Tue',
    isActive: false,
    validKey: 'tuesday',
  },
  wednesday: {
    human: 'Wed',
    isActive: false,
    validKey: 'wednesday',
  },
  thursday: {
    human: 'Thu',
    isActive: false,
    validKey: 'thursday',
  },
  friday: {
    human: 'Fri',
    isActive: false,
    validKey: 'friday',
  },
  saturday: {
    human: 'Sat',
    isActive: false,
    validKey: 'saturday',
  },
  sunday: {
    human: 'Sun',
    isActive: false,
    validKey: 'sunday',
  },
};

interface IDayObj {
    human: string;
    isActive: boolean;
    validKey: string;
}

export type TSupportDateChangeType = 'movedEarlier' | 'movedLater' | 'noChange';

@Injectable()
export class SupportModalService {

  constructor (
    private storeDataService: StoreDataService,
    private imUser: ImUser,
    private dateHelpers: DateHelpers,
  ) {}

  public getFormVariants (mode: string): { title: string; actionButtonLabel: string } {
    switch (mode) {
      case 'CREATE':
        return {
          title: 'Create new support',
          actionButtonLabel: 'Create',
        };
      case 'EDIT':
        return {
          title: 'Edit support',
          actionButtonLabel: 'Update',
        };
      case 'DUPLICATE':
        return {
          title: 'Duplicate support',
          actionButtonLabel: 'Duplicate',
        };
    }
  }

  //
  private getSubjectBuckets (schoolType): any {
    // in the case of hybrid schools, the subjects constant will
    // return two keys for ELA -  'ELA_CORE' and 'ELA_CORE_MS'. They share the same
    // human readble value, 'ELA', which is what the schema accepts anwyays. So
    // just dumping one from the menu.
    const academicSubjects = sortBy(uniq(map(supportSubjectOptionsBySchool[schoolType], sub => sub.humanShort)));
    const regentsSubjects = sortBy(
      reduce(
        RegentsSubject,
        (res, subj) => {
          res.push(subj.longName);
          return res;
        },
        [],
      ),
    );
    // if BOTH regents and acad cats are chosen, the available subject options
    // need to be limited to those that fulfil REGENTS_PREP cat validation requirements first
    // and then also fulfill cat ACADEMIC validation requirements.
    const mergedSubjects = sortBy(
      reduce(
        subjectsForRegentsAndAcademicCatPatches,
        (res, v, k) => {
          res.push(v.display);
          return res;
        },
        [],
      ),
    );
    return {
      regentsSubjects,
      academicSubjects,
      mergedSubjects,
    };
  }

  subjectBuckets: {
    regentsSubjects: any[];
    academicSubjects: any[];
    mergedSubjects: any[];
  };

  public getDynamicSubjects (selectedCats, schoolType) {
    const buckets = this.getSubjectBuckets(schoolType);
    const academicSelected = selectedCats.includes(supportCats.ACADEMIC);
    const regentsSelected = selectedCats.includes(supportCats.REGENTS_PREP);
    if (academicSelected && !regentsSelected) {
      return buckets.academicSubjects;
    } else if (!academicSelected && regentsSelected) {
      return buckets.regentsSubjects;
    } else if (academicSelected && regentsSelected) {
      return buckets.mergedSubjects;
    }
  }

  public getDynamicSubcategories (selectedCats, subcatBuckets): IPickerOption[] {
    const mergedSubCats = reduce(
      subcatBuckets,
      (res, bucket, key) => {
        const cond = selectedCats.includes(key);
        if (cond) res.push(bucket);
        return res;
      },
      [],
    );
    const flatCats = flatten(mergedSubCats);
    return values(flatCats);
  }

  private getGroupedSubcats (cat): IPickerOption[] {
    if (cat.subOptions) {
      return map(cat.subOptions, subOpt => {
        return {
          key: subOpt.value,
          human: subOpt.humanName,
        };
      });
    }
  }

  public getCategoryBasedOptions (schoolType : TValidSchoolTypes): any {
    const res = { categories: [], groupedSubcats: {} };
    const mappedData = reduce(
      SupportCategories,
      (res, cat) => {
        //Leave out regents prep for elementary students.
        if(cat.key === supportCats.REGENTS_PREP && isElementaryOnly(schoolType)) {
          return res;
        }

        if (!cat.key || cat.key === 'COLLEGE_CAREER') return res;
        if (catsWithMeta.includes(cat.key) && cat.key !== supportCats.REGENTS_PREP) {
          res.categories.push({ key: cat.key, human: cat.humanName, metaData: {} });
          res.groupedSubcats[cat.key] = this.getGroupedSubcats(cat);
        } else {
          res.categories.push({ key: cat.key, human: cat.humanName, metaData: {} });
        }
        return res;
      },
      res,
    );
    mappedData.categories = sortBy(compact(mappedData.categories), 'human');
    return mappedData;
  }

  public getTermBasedDateRange (term, schoolTermInfo) {
    return reduce(
      schoolTermInfo,
      (res: any, value) => {
        if (term === value.termOption) {
          res.currentStart = value.termStartDate;
          res.currentEnd = value.termEndDate;
        }
        return res;
      },
      {},
    );
  }

  public getValidActivityLeadsPayload (leads, users) {
    const fullMinis = map(leads, (leadId: string) => {
      return reduce(
        users,
        (res, userDoc) => {
          if (userDoc.key === leadId) {
            res.push(userDoc.user);
          }
          return res;
        },
        [],
      );
    });
    return flatten(fullMinis);
  }

  public getValidCategoriesPayload (categories) {
    return map(categories.value, cat => {
      return {
        category: cat.key,
        metaData: size(cat.metaData) ? cat.metaData : null,
      };
    });
  }

  public getUsers$ (schoolId: string): Observable<IUserMini[]> {
    return this.storeDataService.loadDataAndGetStream$({ schoolId }, getUsersLoadedStatus, LoadUsers, getUsersEntities)
      .pipe(
        rxMap(users => {
          let userMinis = users
            .filter(({ authorizationStatus }) => authorizationStatus === 'FULL_ACCESS')
            .map((user) => this.imUser.toMiniUser(user));

          userMinis = sortBy(userMinis, 
            [user => user.firstName?.toLowerCase(), user => user.lastName?.toLowerCase()]);
          return userMinis
            .map(user => {
              const { firstName, lastName, userId } = user;
              const nameString = `${firstName} ${lastName}`;
              return {
                key: nameString,
                human: nameString,
                user,
                id: userId,
              };
            });
          
        }),
      );
  }

  public getSchool$ (): Observable<ISchool> {
    return this.storeDataService.getDataFromStore$(getSchool);
  }

  public getSupportNames$ (schoolId: string): Observable<string[]> {
    const payload = { schoolId };
    return this.storeDataService
      .loadDataToStore$(payload, getSupportsLoadedStatus, LoadSupports, getSupportsEntitiesList)
      .pipe(rxMap(supports => supports.map(({ name }) => name)));
  }

  public getStudentIdsForSupport$ (schoolId: string, supportId: string) {
    const payload = { schoolId };
    const studentIdHash = {};

    return this.storeDataService
      .loadDataToStore$(payload, getStudentSupportsLoadedStatus, LoadStudentSupports, getStudentSupportsEntitiesList)
      .pipe(
        rxMap((studentSupports: IStudentSupport[]) => {
          return studentSupports.reduce((acc, { status, support: { supportId: supportMiniId }, student: { studentId, schoolStatus } }) => {
            if (supportMiniId === supportId && status !== SupportStatuses.backend.DELETED && schoolStatus === 'A') {
              if (!studentIdHash[studentId]) studentIdHash[studentId] = true;
              acc.push(studentId);
            }
            return acc;
          }, []);
        }),
      );
  }

  public getStudentSupportIds$ (schoolId: string, supportId: string) {
    const payload = { schoolId };
    const studentSupportHash = {};

    return this.storeDataService
      .loadDataToStore$(payload, getStudentSupportsLoadedStatus, LoadStudentSupports, getStudentSupportsEntitiesList)
      .pipe(
        rxMap((studentSupports: IStudentSupport[]) => {
          return studentSupports.reduce((acc, { status, support: { supportId: supportMiniId }, student: { schoolStatus }, _id: studentSupportId }) => {
            if (supportMiniId === supportId && status !== SupportStatuses.backend.DELETED && schoolStatus === 'A') {
              if (!studentSupportHash[studentSupportId]) studentSupportHash[studentSupportId] = true;
              acc.push(studentSupportId);
            }
            return acc;
          }, []);
        }),
      );
  }

  public getInitialDate ({ startDate, endDate, mode, isStartDate }: { startDate?: string, endDate?: string, mode: TSupportModalMode, isStartDate: boolean }) {
    if (mode === 'EDIT') return moment.utc(endDate || startDate).format('YYYY-MM-DD');
    else if (isStartDate) return this.dateHelpers.getFormattedNow('YYYY-MM-DD');
    else return `${moment.utc(startDate).add(1, 'd')}`;
  }

  public convertToACOptions (options: string[]): IACOption[] {
    return options.map(val => ({
      key: val,
      human: val,
      tags: [],
    }));
  }

  public setFrequencies (): IACOption[] {
    return this.convertToACOptions(values(SUPPORT_SCHEDULE_REPEAT_FREQUENCY_FRONTEND));
  }

  public getWeek (repeatsOn: IDaysOfTheWeekBoolean) {
    return map(repeatsOn, (bool, day) => {
      const dayObj = {
        human: SUPPORT_WEEK[day].human,
        isActive: bool,
        validKey: day,
      };
      return dayObj;
    });
  }

  public getRepeatsStringAndFreq ({ repeats, repeatsOn } : { repeats: TValidSchoolSupportRepeatsOptions | undefined, repeatsOn: IDaysOfTheWeekBoolean | undefined }): { selectedRepeats: string, frequencyIsOnce: boolean } {
    let selectedRepeats: string;
    let frequencyIsOnce: boolean;
    switch (repeats) {
      case null:
        break;
      case 'NONE':
        selectedRepeats = 'One time';
        frequencyIsOnce = true;
        break;
      case 'DAILY':
        selectedRepeats = 'Every day';
        break;
      case 'WEEKLY':
        selectedRepeats = this.getWeeklyScheduleString(repeatsOn);
        frequencyIsOnce = false;
        break;
      default:
        selectedRepeats = 'One time';
        frequencyIsOnce = true;
        break;
    }
    return { selectedRepeats, frequencyIsOnce };
  };

  public getWeeklyScheduleString (repeatsOn?: IDaysOfTheWeekBoolean): string {
    let repeatsStr = '';
    if (!repeatsOn) return '';
    const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = repeatsOn;
    const mf = monday && tuesday && wednesday && thursday && friday && !saturday && !sunday;
    const mwf = monday && !tuesday && wednesday && !thursday && friday && !saturday && !sunday;
    const tth = !monday && tuesday && !wednesday && thursday && !friday && !saturday && !sunday;
    if (mf) repeatsStr = 'Every week on M-F';
    if (tth) repeatsStr = 'Every week on T/Th';
    if (mwf) repeatsStr = 'Every week on M/W/F';
    if (!mf && !tth && !mwf) repeatsStr = 'Custom';
    return repeatsStr;
  }

  public getSelectedTerms ({ startTerm, endTerm, mode, currentTerm, termPickerOptionsMap }: { startTerm?: ITermInfoMini, endTerm?: ITermInfoMini, mode: TSupportModalMode, currentTerm: any, termPickerOptionsMap: any }) {
    // If we are duplicating, go ahead and go straight to the current term.
    // Otherwise, only use current term if it is too old to be offered as an option.
    const startTermMini = (mode === 'DUPLICATE' ||
      (!startTerm || !endTerm) || !termPickerOptionsMap.has(startTerm?.yearTerm))
      ? { ...currentTerm }
      : termPickerOptionsMap.get(startTerm.yearTerm);
    const endTermMini = (mode === 'DUPLICATE' ||
     (!startTerm || !endTerm) ||
     !termPickerOptionsMap.has(endTerm?.yearTerm))
      ? { ...currentTerm }
      : termPickerOptionsMap.get(endTerm.yearTerm);
    return { startTerm: startTermMini, endTerm: endTermMini };
  }

  public getScheduleFromFreq ({
    freq,
    repeatsOn,
    repeatsEvery,
  }: {freq: string, repeatsOn: any, repeatsEvery: any}) {
    const {
      ONE_TIME,
      EVERY_DAY,
      EVERY_WEEK_MTWTHF,
      EVERY_WEEK_MWF,
      EVERY_WEEK_TTH,
      CUSTOM,
    } = SUPPORT_SCHEDULE_REPEAT_FREQUENCY_FRONTEND;

    const schedule = {
      repeats: null,
      repeatsEvery: 1,
      repeatsOn: SUPPORT_SCHEDULE_WEEK_OBJ,
    };

    switch (freq) {
      case ONE_TIME:
        schedule.repeats = 'NONE';
        repeatsOn.setErrors(null);
        repeatsEvery.setErrors(null);
        break;
      case EVERY_DAY:
        repeatsOn.setErrors(true);
        repeatsEvery.setErrors(true);
        schedule.repeats = 'DAILY';
        schedule.repeatsOn = {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        };
        break;
      case EVERY_WEEK_MTWTHF:
        schedule.repeats = 'WEEKLY';
        schedule.repeatsOn = {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        };
        break;
      case EVERY_WEEK_MWF:
        schedule.repeats = 'WEEKLY';
        schedule.repeatsOn = {
          monday: true,
          tuesday: false,
          wednesday: true,
          thursday: false,
          friday: true,
          saturday: false,
          sunday: false,
        };
        break;
      case EVERY_WEEK_TTH:
        schedule.repeats = 'WEEKLY';
        schedule.repeatsOn = {
          monday: false,
          tuesday: true,
          wednesday: false,
          thursday: true,
          friday: false,
          saturday: false,
          sunday: false,
        };
        break;
      case CUSTOM:
        schedule.repeats = 'WEEKLY';
        repeatsOn.setErrors(true);
        repeatsEvery.setErrors(true);
        break;
    }

    return schedule;
  }

  setFreqControlErrors (controls: any) {
    const { repeatsOn, repeatsEvery, dateRange } = controls;
    repeatsOn.setErrors(true);
    repeatsEvery.setErrors(true);
    dateRange.setErrors(null);
  }

  updateWeekdaysSelections (weekdaysObj: IDayObj[], dayObj: IDayObj) {
    return weekdaysObj.reduce((acc, weekdaysObj) => {
      if (weekdaysObj.human === dayObj.human) weekdaysObj = dayObj;
      acc.push(weekdaysObj);
      return acc;
    }, []);
  }

  public getTermMini ({ termYear, district, termPickerOptionsMap }: {
    termYear: string, district: TDistricts, termPickerOptionsMap: any
  }): ITermInfoMini | null {
    if (!termYear) {
      const schoolYear = getSchoolYearByDistrict(district);
      return {
        yearTerm: null,
        schoolYear,
        termStartDate: null,
        termEndDate: null,
      };
    }
    const term = termPickerOptionsMap.get(termYear);
    const { yearTerm, schoolYear, termStartDate, termEndDate } = term;
    return { yearTerm, schoolYear, termStartDate, termEndDate };
  }

  public getConfirmDateChangeMessage ({ startDate, endDate, deleteOrReassign }: {startDate?: string, endDate?: string, deleteOrReassign: 'confirmDelete' | 'reassignSupport' }) : string {
    let timePeriod: string;
    if (!startDate && !endDate) return 'Please confirm changes.';
    if (startDate && endDate) {
      timePeriod = 'start and end dates';
    } else if (startDate) {
      timePeriod = 'start date';
    } else {
      timePeriod = 'end date';
    }
    return deleteOrReassign === 'reassignSupport'
      ? 'Do you want all student dates to be moved to align with the changes you\'ve made to the support dates?'
      : `Are you sure you want to move the ${timePeriod} for this support? You may have already taken attendance for this support, and moving the ${timePeriod} will result in losing any attendance that falls outside of the new ${timePeriod}.`;
  }

  public getAttRecordData ({ startDate, endDate }: {startDate?: string, endDate?: string}) {
    return {
      title: 'Confirm',
      message: this.getConfirmDateChangeMessage({
        startDate,
        endDate,
        deleteOrReassign: 'confirmDelete',
      }),
      confirmText: 'Yes',
      cancelText: 'No',
    };
  }

  // if original start date hasn't occurred yet, no att records exist yet
  // users cannot take future attendance or change completed supports
  // therefore only needs checking if original start or new end dates changed to before today
  public haveRelevantDatesHappened ({ originalStartDate, newEndDate, startChanged } : {originalStartDate: string, newEndDate?: string, startChanged: boolean}) {
    const today = moment();
    const hasOriginalStartOccurred = moment(originalStartDate).isSameOrBefore(today);
    let hasNewEndOccurred: boolean;
    if (hasOriginalStartOccurred && newEndDate) {
      hasNewEndOccurred = moment(newEndDate).isSameOrBefore(today);
    }
    if ((startChanged && hasOriginalStartOccurred) || hasNewEndOccurred) return true;
    return false;
  }

  public checkDateChange (originalDate: string, newDate: string) {
    let dateChangeType: TSupportDateChangeType;
    const origDateObj = moment(originalDate);
    const newDateObj = moment(newDate);
    if (moment(newDateObj).isBefore(origDateObj)) dateChangeType = 'movedEarlier';
    else if (moment(newDateObj).isAfter(origDateObj)) dateChangeType = 'movedLater';
    else dateChangeType = 'noChange';
    return dateChangeType;
  }
}
