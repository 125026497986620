// TODO UPDATE AT REGENTS ADMIN FLIP: Make sure this includes all the correct Regents exam codes
// Regents exam code changes should be included in the description of the term flip pivotal card

// https://wiki.nycenet.edu/display/HSST/Regents#Regents-ExamCodes

export const RegentsExamCodes = {
  HIGH_SCHL: {
    lote: '',
    compEla: 'EXRL',
    ccEla: 'EXRC',
    global: 'HXRT', // previously HXRG,
    globalTwo: 'HXRC',
    us: 'HXRU',
    usFramework: 'HXRK',
    intAlg: 'MXRE',
    ccAlg: 'MXRF',
    oldGeom: 'MXRG',
    ccGeom: 'MXRJ',
    oldTrig: 'MXRT',
    ccTrig: 'MXRN',
    livingEnv: 'SXRK',
    physics: 'SXRP',
    earth: 'SXRU',
    chem: 'SXRX',
    nysslsBio: 'SXR3',
    nysslsEarth: 'SXR2',
  },
  MIDDLE_SCHL: {
    lote: '',
    compEla: 'EXZL',
    ccEla: 'EXZC',
    global: 'HXZT', // previously HXZG
    globalTwo: 'HXZC',
    us: 'HXZU',
    usFramework: 'HXZK',
    intAlg: 'MXZE',
    ccAlg: 'MXZF',
    oldGeom: 'MXZG',
    ccGeom: 'MXZJ',
    oldTrig: 'MXZT',
    ccTrig: 'MXZN',
    livingEnv: 'SXZK',
    physics: 'SXZP',
    earth: 'SXZU',
    chem: 'SXZX',
    nysslsBio: 'SXZ3',
    nysslsEarth: 'SXZ2',
  },
  LOTE: {
    ARABIC: 'FXTA',
    HAITIAN: 'FXTB',
    'CHINESE-SIMPLE': 'FXTC',
    HINDI: 'FXTD',
    GREEK: 'FXTE',
    FRENCH: 'FXTF',
    GERMAN: 'FXTG',
    HEBREW: 'FXTH',
    BENGALI: 'FXTI',
    JAPANESE: 'FXTJ',
    KOREAN: 'FXTK',
    LATIN: 'FXTL',
    'CHINESE-TRAD': 'FXTM',
    ALBANIAN: 'FXTN',
    RUSSIAN: 'FXTR',
    SPANISH: 'FXTS',
    ITALIAN: 'FXTT',
    URDU: 'FXTU',
    VIETNAMESE: 'FXTV',
    PUNJABI: 'FXTW',
    POLISH: 'FXTZ',
    'AMERICAN-SIGN-LANGUAGE': 'FXTP',
  },
};
