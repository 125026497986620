import { HowToAddSupportModalModule } from './support/how-to-add-support-modal/how-to-add-support-modal.module';
import { ConsentStatusGuardianModule } from './consent-status/consent-status-modal.module';
import { CheckInLogModalModule } from './check-in-logs/check-in-log-modal.module';
import { ProgramPointModalModule } from './ecfik-program-point/program-point-modal/program-point-modal.module';
import { BehavioralIncidentDetailModalModule } from './behavioral-incident-detail/behavioral-incident-detail.module';
import { EditECFIKGuardianModule } from './edit-ecfik-guardian/edit-ecfik-guardian-modal.module';
import { RegentsPlanModalModule } from './regents-plan-modal/regents-plan-modal.module';
import { GradPanelModalModule } from './grad-panel-modal/grad-panel-modal.module';
import { ScheduledCoursePlanModule } from './../components/course-plans/scheduled-course-plan/scheduled-course-plan.module';
import { ManualCoursePlanModule } from './../components/course-plans/manual-course-plan/manual-course-plan.module';
import { CoursePlansModalShellModule } from './course-plans/course-plans-modal-shell.module';
import { FuturePlanModalModule } from './experiences/experiences-modal/future-plan/future-plan-modal.module';
import { ExperiencesModalModule } from './experiences/experiences-modal/experiences-modal.module';
import { ExperienceDetailsModalModule } from './experiences/experiences-modal/experience-details-modal/experience-details-modal.module';
import { CollegeNowEligibilityModalModule } from './college-now/student-eligibility/college-now-eligibility-modal.module';
import { PostsecFinancialAidModalModule } from './postsec-financial-aid/postsec-financial-aid-modal.module';
import { RegentsNextAdminConflictModalModule } from './regents-next-admin-conflict/regents-next-admin-conflict.module';
import { LoteSchedulerModalModule } from './lote-scheduler/lote-scheduler-modal.module';
import { CreditGapFreeformModalModule } from './credit-gap-freeform/credit-gap-freeform-course-modal.module';
import { CreditGapsFreeformCourseModal } from './credit-gap-freeform/credit-gap-freeform-course-modal.component';
import { CreditGapsCoursePickerModal } from './credit-gaps-course-picker/credit-gaps-course-picker-modal.component';
import { CreditGapsCoursePickerModalModule } from './credit-gaps-course-picker/credit-gaps-course-picker-modal.module';
import { BackgroundJobSpinnerModalModule } from './background-job-spinner-modal/background-job-spinner-modal.module';
import { ErrorModalModule } from './error/error-modal.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NvDesignLibraryModule } from '../../../nvps-libraries/design/nv-design-library.module';
import { BackgroundJob } from '../services/background-job/background-job.service';
import { FormValidatorsService } from '../services/form-validators/form-validators.service';
import { SnackBarService } from '../services/snackbar/snackbar.service';
import { MaterialModule } from './../modules/material.module';
import { AddCareerModalModule } from './add-career/add-career.module';
import { AlertModalcomponent } from './alert/alert-modal.component';
import { AssignSupportModalService } from './assign-support/assign-support-modal.service';
import { AssignSupportModalModule } from './assign-support/assign-support.modal.module';
import { AssignPlansModule } from './assign-plans/assign-plans.module';
import { EditContactInfoModalModule } from './edit-contact-info-modal/edit-contact-info-modal.module';
import { BatchEditModule } from './batch-edit/batch-edit-modal.module';
import { BatchEditGroupModule } from './batch-edit-group/batch-edit-group-modal.module';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';
import { CsvUploadConfirmModalComponent } from './csv-upload/confirm-modal/csv-upload-confirm-modal.component';
import { CsvUploadErrorModalComponent } from './csv-upload/error-modal/csv-upload-error-modal.component';
import { CsvUploadFixErrorsModalComponent } from './csv-upload/fix-errors-modal/csv-upload-fix-errors-modal.component';
import { EditGridColumnsModalModule } from './edit-grid-columns/edit-grid-columns-modal.module';
import { EndorsementsCredentialsModalModule } from './endorsements-credentials/endorsements-credentials-modal.module';
import { HistoryAllHelperService } from './history-all/history-all-helpers.service';
import { HistoryAllModalComponent } from './history-all/history-all-modal.component';
import { ListSummaryModalModule } from './list-summary/list-summary-modal.module';
import { ModalsService } from './modals.service';
import { SyncedSheetModalModule } from './other-tools/synced-sheet-modal/synced-sheet-modal.module';
import { SyncedSheetModalComponent } from './other-tools/synced-sheet-modal/synced-sheet-modal.component';
import { ShelterClusterUserManagementModalModule } from './user-management/cluster-user/shelter-cluster-user/shelter-user-management-modal.module';
import { StudentPathsModule } from './student-paths/student-paths-modal.module';
import { StudentReportModalModule } from './student-report/student-report.module';
import { StudentSupportModalV2Module } from './student-support/student-support-modal-v2.module';
import { StudentSupportsHistoricalDataModule } from './student-supports-historical-data/student-supports-historical-data-modal.module';
import { SupportAttendanceLogsModalModule } from './support-attendance-logs/support-attendance-logs-modal.module';
import { SupportModalModule } from './support/support-modal.module';
import { SupportStatusModalModule } from './support/support-status-modal.component.ts/support-status-modal.module';
import { SupportFrequencyModalModule } from './support/support-frequency-modal/support-frequency-modal.module';
import { SchoolClusterUserManagementModalModule } from './user-management/cluster-user/school-cluster-user/user-management-modal.module';
import { SchoolUserManagementModalModule } from './user-management/school-user/school-user-management-modal.module';
import { AssignPrepModalComponent } from './master-program/assign-prep-modal/assign-prep-modal.component';
import { RemovePrepModalComponent } from './master-program/remove-prep-modal/remove-prep-modal.component';
import { NotesModalShellModule } from './notes/notes-modal-shell.module';
import { NotificationsModalShellModule } from './notifications/notifications-modal-shell.module';
import { NoteDetailsModalModule } from './notes/note-details-modal/note-details-modal.module';
import { StudentSelSupportsHistoricalDataModule } from './student-sel-supports-historical-data/student-sel-supports-historical-data-modal.module';
import { PostsecDataSourcesModalModule } from './postsec-data-sources/postsec-data-sources-modal.module';
import { ShelterSuccessMentorShellModalModule } from './shelter/shelter-success-mentor/shelter-success-mentor-shell/shelter-success-mentor-shell.module';
import { HistoryAllModalModule } from './history-all/history-all-modal.module';
import { GridViewNameModalModule } from './gridview-name/gridview-name-modal.module';
import { TaskModalModule } from './task/task-modal.module';
import { ResultsModalModule } from './results/results-modal.module';
import { TasksNotesActivityModalModule } from './tasks-notes-activity-modal/tasks-notes-activity-modal.module';
import { AddMockRegentsExamsModule } from './add-mock-regents-exams/add-mock-regents-exams.module';
import { MultiSelectFilterModalModule } from './multi-select-filter/multi-select-filter-modal.module';
@NgModule({
  declarations: [
    AlertModalcomponent,
    ConfirmModalComponent,
    CsvUploadErrorModalComponent,
    CsvUploadConfirmModalComponent,
    CsvUploadFixErrorsModalComponent,
    AssignPrepModalComponent,
    RemovePrepModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MaterialModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSnackBarModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BatchEditModule,
    BatchEditGroupModule,
    StudentPathsModule,
    StudentReportModalModule,
    AssignSupportModalModule,
    EditContactInfoModalModule,
    AddCareerModalModule,
    ListSummaryModalModule,
    NvDesignLibraryModule,
    SupportAttendanceLogsModalModule,
    StudentSupportsHistoricalDataModule,
    SchoolClusterUserManagementModalModule,
    SchoolUserManagementModalModule,
    StudentSupportModalV2Module,
    SupportModalModule,
    SupportFrequencyModalModule,
    SupportStatusModalModule,
    ShelterClusterUserManagementModalModule,
    EditGridColumnsModalModule,
    EndorsementsCredentialsModalModule,
    ErrorModalModule,
    SyncedSheetModalModule,
    BackgroundJobSpinnerModalModule,
    CreditGapsCoursePickerModalModule,
    CreditGapFreeformModalModule,
    NotesModalShellModule,
    NoteDetailsModalModule,
    LoteSchedulerModalModule,
    RegentsNextAdminConflictModalModule,
    StudentSelSupportsHistoricalDataModule,
    PostsecDataSourcesModalModule,
    PostsecFinancialAidModalModule,
    ShelterSuccessMentorShellModalModule,
    HistoryAllModalModule,
    CoursePlansModalShellModule,
    ManualCoursePlanModule,
    ScheduledCoursePlanModule,
    ExperiencesModalModule,
    ExperienceDetailsModalModule,
    FuturePlanModalModule,
    GridViewNameModalModule,
    AssignPlansModule,
    EditECFIKGuardianModule,
    CheckInLogModalModule,
    GradPanelModalModule,
    RegentsPlanModalModule,
    TaskModalModule,
    ResultsModalModule,
    BehavioralIncidentDetailModalModule,
    TasksNotesActivityModalModule,
    NotificationsModalShellModule,
    ProgramPointModalModule,
    CollegeNowEligibilityModalModule,
    AddMockRegentsExamsModule,
    MultiSelectFilterModalModule,
    ConsentStatusGuardianModule,
    HowToAddSupportModalModule,
  ],
  exports: [
    HistoryAllModalComponent,
    AlertModalcomponent,
    ConfirmModalComponent,
    CsvUploadErrorModalComponent,
    CsvUploadConfirmModalComponent,
    CsvUploadFixErrorsModalComponent,
    AssignPrepModalComponent,
    RemovePrepModalComponent,
  ],
  providers: [
    ModalsService,
    FormValidatorsService,
    HistoryAllHelperService,
    AssignSupportModalService,
    BackgroundJob,
    SnackBarService,
  ],
})
export class ModalsModule {}
