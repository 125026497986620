import { CurrentSchoolYear } from './current-school-year.constant';

export const GRIDVIEW_MAPPING = [
  {
    foci: 'ON_TRACK_FOR_GRAD',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetGrad1',
  },
  {
    foci: 'ON_TRACK_FOR_GRAD',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetGrad2',
  },
  {
    foci: 'HAS_GRAD_PLAN',
    accessType: 'GradRespondButton',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetGrad3',
  },
  {
    foci: 'HAS_GRAD_PLAN',
    accessType: 'GradRespondButton',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetGrad4',
  },
  {
    foci: 'PERCENT_ON_TRACK_IN_CREDITS',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCredits1',
  },
  {
    foci: 'PERCENT_ON_TRACK_IN_CREDITS',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetCredits2',
  },
  {
    foci: 'SCHED_TO_END_TERM_ON_TRACK',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCredits3',
  },
  {
    foci: 'SCHED_TO_END_TERM_ON_TRACK',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetCredits4',
  },
  {
    foci: 'PERCENT_FULLY_PLANNED_TO_ADDRESS_ALL_CREDIT_GAPS',
    accessType: 'CreditsRespondTab',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCredits3',
  },
  {
    foci: 'PERCENT_FULLY_PLANNED_TO_ADDRESS_ALL_CREDIT_GAPS',
    accessType: 'CreditsRespondTab',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetCredits4',
  },
  {
    foci: 'ON_TRACK_IN_REGENTS',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetRegents1',
  },
  {
    foci: 'ON_TRACK_IN_REGENTS',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetRegents2',
  },
  {
    foci: 'ON_TRACK_FOR_CR',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetRegents1',
  },
  {
    foci: 'ON_TRACK_FOR_CR',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetRegents2',
  },
  {
    foci: 'REGENTS_SCHED_STATUS',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetRegents3',
  },
  {
    foci: 'REGENTS_SCHED_STATUS',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetRegents4',
  },
  {
    foci: 'REGENTS_PLANNING_STATUS',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetRegents3',
  },
  {
    foci: 'REGENTS_PLANNING_STATUS',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetRegents4',
  },
  {
    foci: 'PERCENT_STUDENTS_FULLY_SUPPORTED_FOR_REGENTS',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetRegents5',
  },
  {
    foci: 'PERCENT_STUDENTS_FULLY_SUPPORTED_FOR_REGENTS',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetRegents6',
  },
  {
    foci: 'ACADEMIC_PERCENT_GPA_80_PLUS',
    gridType: [
      'hs', 'summer_hs',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetAcademics1',
  },
  {
    foci: 'ACADEMIC_PERCENT_GPA_80_PLUS',
    gridType: [
      'transfer', 'summer_transfer',
    ],
    gridViewId: 'presetAcademics2',
  },
  {
    foci: 'ACADEMIC_PERCENT_GPA_80_PLUS_HYBRID',
    gridType: [
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
    ],
    gridViewId: 'presetAcademics4',
  },
  {
    foci: 'ACADEMIC_PERCENT_GPA_THREE_PLUS',
    gridType: [
      'hs', 'summer_hs',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetAcademics1',
  },
  {
    foci: 'ACADEMIC_PERCENT_GPA_THREE_PLUS',
    gridType: [
      'transfer', 'summer_transfer',
    ],
    gridViewId: 'presetAcademics2',
  },
  {
    foci: 'ACADEMIC_PERCENT_GPA_THREE_PLUS',
    gridType: [
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
    ],
    gridViewId: 'presetAcademics4',
  },
  {
    foci: 'ACADEMIC_ELA_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'ms', 'summer_ms',
      '6To8', 'summer_6To8',
      'kTo8', 'summer_kTo8',
    ],
    gridViewId: 'presetAcademics3',
  },
  {
    foci: 'ACADEMIC_ELA_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
    ],
    gridViewId: 'presetAcademics4',
  },
  {
    foci: 'ACADEMIC_ELA_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'es', 'summer_es',
    ],
    gridViewId: 'presetAcademics5',
  },
  {
    foci: 'ACADEMIC_MATH_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'ms', 'summer_ms',
      '6To8', 'summer_6To8',
      'kTo8', 'summer_kTo8',
    ],
    gridViewId: 'presetAcademics3',
  },
  {
    foci: 'ACADEMIC_MATH_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
    ],
    gridViewId: 'presetAcademics4',
  },
  {
    foci: 'ACADEMIC_MATH_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'es', 'summer_es',
    ],
    gridViewId: 'presetAcademics5',
  },
  {
    foci: 'CHRONIC_ABSENTEEISM_RATE',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
    ],
    gridViewId: 'presetAttendance1',
  },
  {
    foci: 'CHRONIC_ABSENTEEISM_RATE',
    gridType: [
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'SHELTER_ACTIVE_ONLY_STUDENTS',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetShelterDefault1',
  },
  {
    foci: 'SHELTER_PERCENT_IS_SWD_STUDENT',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetShelterDefault1',
  },
  {
    foci: 'SHELTER_ELL_STUDENTS',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetShelterDefault1',
  },
  {
    foci: 'SHELTER_ADMITTED_WITHIN_LAST_20_DAYS',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetShelterDefault1',
  },
  {
    foci: 'SHELTER_YTD_ATTD',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetShelterAttendance1',
  },
  {
    foci: 'SHELTER_CHRONIC_ABSENTEEISM_RATE',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetShelterAttendance1',
  },
  {
    foci: 'SHELTER_ATTENDANCE_ON_LAST_DAY',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetShelterAttendance1',
  },
  {
    foci: 'SHELTER_ABSENT_PAST_2_PLUS_DAYS',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
    ],
    gridViewId: 'presetShelterAttendance1',
  },
  {
    foci: 'CHRONIC_ABSENTEEISM_RATE_TRANSFER',
    gridType: [
      'transfer', 'summer_transfer',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'YTD_ATTD',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
    ],
    gridViewId: 'presetAttendance1',
  },
  {
    foci: 'YTD_ATTD',
    gridType: [
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
      'transfer',
      'summer_transfer',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'ABSENT_PAST_2_PLUS_DAYS',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
    ],
    gridViewId: 'presetAttendance1',
  },
  {
    foci: 'ABSENT_PAST_2_PLUS_DAYS',
    gridType: [
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
      'transfer',
      'summer_transfer',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'ATTENDANCE_ON_LAST_DAY',
    gridType: [
      'kTo8', 'summer_kTo8',
      'es', 'summer_es',
      'ms', 'summer_ms',
    ],
    gridViewId: 'presetAttendance1',
  },
  {
    foci: 'ATTENDANCE_ON_LAST_DAY',
    gridType: [
      'hs', 'summer_hs',
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
      'charter', 'summer_charter',
      'aim', 'summer_aim',
      'transfer',
      'summer_transfer',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'ACTIVE_ONLY_STUDENTS',
    gridType: [
      'es', 'kTo5',
      '6To8', 'ms',
      'kTo8', 'summer_es',
      'summer_kTo5', 'summer_kTo8',
      'summer_6To8', 'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'ACTIVE_ONLY_STUDENTS',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'transfer',
      'summer_hs', 'summer_kTo12',
      'summer_6To12', 'summer_aim',
      'summer_charter', 'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'ACTIVE_ONLY_STUDENTS_EMS_HYBRID',
    gridType: [
      'es', 'kTo5',
      '6To8', 'ms',
      'kTo8', 'summer_es',
      'summer_kTo5', 'summer_kTo8',
      'summer_6To8', 'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'ACTIVE_ONLY_STUDENTS_EMS_HYBRID',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'transfer',
      'summer_hs', 'summer_kTo12',
      'summer_6To12', 'summer_aim',
      'summer_charter', 'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'PERCENT_IS_SWD_STUDENT',
    gridType: [
      'es', 'kTo5',
      '6To8', 'ms',
      'kTo8', 'summer_es',
      'summer_kTo5', 'summer_kTo8',
      'summer_6To8', 'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'PERCENT_IS_SWD_STUDENT',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'transfer',
      'summer_hs', 'summer_kTo12',
      'summer_6To12', 'summer_aim',
      'summer_charter', 'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'PERCENT_HOUSING_INSECURE_STUDENTS',
    gridType: [
      'es', 'kTo5',
      '6To8', 'ms',
      'kTo8', 'summer_es',
      'summer_kTo5', 'summer_kTo8',
      'summer_6To8', 'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'PERCENT_HOUSING_INSECURE_STUDENTS',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'transfer',
      'summer_hs', 'summer_kTo12',
      'summer_6To12', 'summer_aim',
      'summer_charter', 'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'ELL_STUDENTS',
    gridType: [
      'es', 'kTo5',
      '6To8', 'ms',
      'kTo8', 'summer_es',
      'summer_kTo5', 'summer_kTo8',
      'summer_6To8', 'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'ELL_STUDENTS',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'transfer',
      'summer_hs', 'summer_kTo12',
      'summer_6To12', 'summer_aim',
      'summer_charter', 'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'PERCENT_STUDENTS_WITH_AT_LEAST_1_APP_TO_ANY_CUNY',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'summer_hs',
      'summer_kTo12', 'summer_6To12',
      'summer_aim', 'summer_charter',
    ],
    gridViewId: 'presetPostsec1',
  },
  {
    foci: 'PERCENT_STUDENTS_WITH_AT_LEAST_1_APP_TO_ANY_CUNY_TRANSFER',
    gridType: [
      'transfer', 'summer_transfer',
    ],
    gridViewId: 'presetPostsec2',
  },
  {
    foci: 'PERCENT_STUDENTS_WITH_FAFSA_PROCESSED_OR_WAIVED',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'summer_hs',
      'summer_kTo12', 'summer_6To12',
      'summer_aim', 'summer_charter',
    ],
    gridViewId: 'presetPostsec1',
  },
  {
    foci: 'PERCENT_STUDENTS_WITH_FAFSA_PROCESSED_OR_WAIVED_TRANSFER',
    gridType: [
      'transfer', 'summer_transfer',
    ],
    gridViewId: 'presetPostsec2',
  },
  {
    foci: 'PERCENT_STUDENTS_WITH_TAP_PROCESSED_OR_WAIVED',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'summer_hs',
      'summer_kTo12', 'summer_6To12',
      'summer_aim', 'summer_charter',
    ],
    gridViewId: 'presetPostsec1',
  },
  {
    foci: 'PERCENT_STUDENTS_WITH_TAP_PROCESSED_OR_WAIVED_TRANSFER',
    gridType: [
      'transfer', 'summer_transfer',
    ],
    gridViewId: 'presetPostsec2',
  },
  {
    foci: 'PLANS_IN_NEED_OF_REVIEW',
    gridType: [
      'hs', 'kTo12',
      '6To12',
      'charter', 'summer_hs',
      'summer_kTo12', 'summer_6To12',
      'summer_charter',
    ],
    gridViewId: 'presetGrad3',
  },
  {
    foci: 'PLANS_IN_NEED_OF_REVIEW',
    gridType: [
      'transfer', 'summer_transfer', 'aim', 'summer_aim',
    ],
    gridViewId: 'presetGrad4',
  },
  {
    foci: 'NEED_GRAD_PLANS',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'charter', 'summer_hs',
      'summer_kTo12', 'summer_6To12',
      'summer_charter',
    ],
    gridViewId: 'presetGrad3',
  },
  {
    foci: 'NEED_GRAD_PLANS',
    gridType: [
      'transfer', 'summer_transfer', 'aim', 'summer_aim',
    ],
    gridViewId: 'presetGrad4',
  },
  {
    foci: 'NEED_COURSE_PLANS',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'charter', 'summer_hs',
      'summer_kTo12', 'summer_6To12', 'summer_charter',
    ],
    gridViewId: 'presetCredits3',
  },
  {
    foci: 'NEED_COURSE_PLANS',
    gridType: [
      'transfer', 'summer_transfer', 'aim',
      'summer_aim',
    ],
    gridViewId: 'presetCredits4',
  },
  {
    foci: 'NEED_REGENTS_PLANS',
    gridType: [
      'hs', 'kTo12',
      '6To12',
      'charter', 'summer_hs',
      'summer_kTo12', 'summer_6To12',
      'summer_charter',
    ],
    gridViewId: 'presetRegents3',
  },
  {
    foci: 'NEED_REGENTS_PLANS',
    gridType: [
      'transfer', 'summer_transfer', 'aim', 'summer_aim',
    ],
    gridViewId: 'presetRegents4',
  },
  {
    foci: 'NEED_REGENTS_SUPPORTS',
    gridType: [
      'hs', 'kTo12',
      '6To12',
      'charter', 'summer_hs',
      'summer_kTo12', 'summer_6To12',
      'summer_charter',
    ],
    gridViewId: 'presetRegents5',
  },
  {
    foci: 'NEED_REGENTS_SUPPORTS',
    gridType: [
      'transfer', 'summer_transfer', 'summer_aim', 'aim',
    ],
    gridViewId: 'presetRegents6',
  },
  {
    foci: 'ADMITTED_WITHIN_LAST_20_DAYS',
    gridType: [
      'es', 'kTo5',
      '6To8', 'ms',
      'kTo8', 'summer_es',
      'summer_kTo5', 'summer_kTo8',
      'summer_6To8', 'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'ADMITTED_WITHIN_LAST_20_DAYS',
    gridType: [
      'hs', 'kTo12',
      '6To12', 'aim',
      'charter', 'transfer',
      'summer_hs', 'summer_kTo12',
      'summer_6To12', 'summer_aim',
      'summer_charter', 'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'ACADEMIC_PERCENT_HS_PASSING_ALL_COURSES_GENERIC_LABEL',
    gridType: [
      'hs', 'summer_hs',
      'charter', 'summer_charter',
    ],
    gridViewId: 'presetAcademics1',
  },
  {
    foci: 'ACADEMIC_PERCENT_HS_PASSING_ALL_COURSES_GENERIC_LABEL',
    gridType: [
      'aim', 'summer_aim',
      'transfer', 'summer_transfer',
    ],
    gridViewId: 'presetAcademics2',
  },
  {
    foci: 'ACADEMIC_PERCENT_MS_PASSING_ALL_COURSES_GENERIC_LABEL',
    gridType: [
      'ms', 'summer_ms',
      'kTo8', 'summer_kTo8',
    ],
    gridViewId: 'presetAcademics3',
  },
  {
    foci: 'ACADEMIC_PERCENT_MS_PASSING_ALL_COURSES',
    gridType: [
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
    ],
    gridViewId: 'presetAcademics4',
  },
  {
    foci: 'ACADEMIC_PERCENT_HS_PASSING_ALL_COURSES',
    gridType: [
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
    ],
    gridViewId: 'presetAcademics4',
  },
  {
    foci: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCore4',
  },
  {
    foci: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ',
    gridType: ['transfer', 'summer_transfer'],
    gridViewId: 'presetCore4Transfer',
  },
  {
    foci: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCore4',
  },
  {
    foci: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS',
    gridType: ['transfer', 'summer_transfer'],
    gridViewId: 'presetCore4Transfer',
  },
  {
    foci: 'COUNT_STUDENTS_HAS_ATTENDANCE_ALERT',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCore4',
  },
  {
    foci: 'COUNT_STUDENTS_HAS_ATTENDANCE_ALERT',
    gridType: ['transfer', 'summer_transfer'],
    gridViewId: 'presetCore4Transfer',
  },
  {
    foci: 'COUNT_STUDENTS_HAS_GRADES_ALERT',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCore4',
  },
  {
    foci: 'COUNT_STUDENTS_HAS_GRADES_ALERT',
    gridType: ['transfer', 'summer_transfer'],
    gridViewId: 'presetCore4Transfer',
  },
  {
    foci: 'COUNT_STUDENTS_HAS_CREDITS_ALERT',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCore4',
  },
  {
    foci: 'COUNT_STUDENTS_HAS_CREDITS_ALERT',
    gridType: ['transfer', 'summer_transfer'],
    gridViewId: 'presetCore4Transfer',
  },
  {
    foci: 'COUNT_STUDENTS_HAS_REGENTS_ALERT',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCore4',
  },
  {
    foci: 'COUNT_STUDENTS_HAS_REGENTS_ALERT',
    gridType: ['transfer', 'summer_transfer'],
    gridViewId: 'presetCore4Transfer',
  },
];

export const GRIDVIEW_MAPPING_LANSING = [
  {
    foci: 'ABSENT_PAST_2_PLUS_DAYS',
    gridType: ['kTo8', 'summer_kTo8', 'es', 'summer_es', 'ms', 'summer_ms'],
    gridViewId: 'presetAttendance1',
  },
  {
    foci: 'ABSENT_PAST_2_PLUS_DAYS',
    gridType: [
      'hs',
      'summer_hs',
      'kTo12',
      'summer_kTo12',
      '6To12',
      'summer_6To12',
      'charter',
      'summer_charter',
      'aim',
      'summer_aim',
      'transfer',
      'summer_transfer',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'ACTIVE_ONLY_STUDENTS',
    gridType: [
      'es',
      'kTo5',
      '6To8',
      'ms',
      'kTo8',
      'summer_es',
      'summer_kTo5',
      'summer_kTo8',
      'summer_6To8',
      'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'ACTIVE_ONLY_STUDENTS',
    gridType: [
      'hs',
      'kTo12',
      '6To12',
      'aim',
      'charter',
      'transfer',
      'summer_hs',
      'summer_kTo12',
      'summer_6To12',
      'summer_aim',
      'summer_charter',
      'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'ACTIVE_ONLY_STUDENTS_EMS_HYBRID',
    gridType: [
      'es',
      'kTo5',
      '6To8',
      'ms',
      'kTo8',
      'summer_es',
      'summer_kTo5',
      'summer_kTo8',
      'summer_6To8',
      'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'ACTIVE_ONLY_STUDENTS_EMS_HYBRID',
    gridType: [
      'hs',
      'kTo12',
      '6To12',
      'aim',
      'charter',
      'transfer',
      'summer_hs',
      'summer_kTo12',
      'summer_6To12',
      'summer_aim',
      'summer_charter',
      'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'ATTENDANCE_ON_LAST_DAY',
    gridType: ['kTo8', 'summer_kTo8', 'es', 'summer_es', 'ms', 'summer_ms'],
    gridViewId: 'presetAttendance1',
  },
  {
    foci: 'ATTENDANCE_ON_LAST_DAY',
    gridType: [
      'hs',
      'summer_hs',
      'kTo12',
      'summer_kTo12',
      '6To12',
      'summer_6To12',
      'charter',
      'summer_charter',
      'aim',
      'summer_aim',
      'transfer',
      'summer_transfer',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'CHRONIC_ABSENTEEISM_RATE',
    gridType: ['kTo8', 'summer_kTo8', 'es', 'summer_es', 'ms', 'summer_ms'],
    gridViewId: 'presetAttendance1',
  },
  {
    foci: 'CHRONIC_ABSENTEEISM_RATE',
    gridType: [
      'hs',
      'summer_hs',
      'kTo12',
      'summer_kTo12',
      '6To12',
      'summer_6To12',
      'charter',
      'summer_charter',
      'aim',
      'summer_aim',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'ELL_STUDENTS',
    gridType: [
      'es',
      'kTo5',
      '6To8',
      'ms',
      'kTo8',
      'summer_es',
      'summer_kTo5',
      'summer_kTo8',
      'summer_6To8',
      'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'ELL_STUDENTS',
    gridType: [
      'hs',
      'kTo12',
      '6To12',
      'aim',
      'charter',
      'transfer',
      'summer_hs',
      'summer_kTo12',
      'summer_6To12',
      'summer_aim',
      'summer_charter',
      'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'PERCENT_FULLY_PLANNED_TO_ADDRESS_ALL_CREDIT_GAPS',
    accessType: 'CreditsRespondTab',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCredits1',
  },
  {
    foci: 'PERCENT_FULLY_PLANNED_TO_ADDRESS_ALL_CREDIT_GAPS',
    accessType: 'CreditsRespondTab',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetCredits4',
  },
  {
    foci: 'PERCENT_HOUSING_INSECURE_STUDENTS',
    gridType: [
      'es',
      'kTo5',
      '6To8',
      'ms',
      'kTo8',
      'summer_es',
      'summer_kTo5',
      'summer_kTo8',
      'summer_6To8',
      'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'PERCENT_HOUSING_INSECURE_STUDENTS',
    gridType: [
      'hs',
      'kTo12',
      '6To12',
      'aim',
      'charter',
      'transfer',
      'summer_hs',
      'summer_kTo12',
      'summer_6To12',
      'summer_aim',
      'summer_charter',
      'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'PERCENT_IS_SWD_STUDENT',
    gridType: [
      'es',
      'kTo5',
      '6To8',
      'ms',
      'kTo8',
      'summer_es',
      'summer_kTo5',
      'summer_kTo8',
      'summer_6To8',
      'summer_ms',
    ],
    gridViewId: 'presetDefault1',
  },
  {
    foci: 'PERCENT_IS_SWD_STUDENT',
    gridType: [
      'hs',
      'kTo12',
      '6To12',
      'aim',
      'charter',
      'transfer',
      'summer_hs',
      'summer_kTo12',
      'summer_6To12',
      'summer_aim',
      'summer_charter',
      'summer_transfer',
    ],
    gridViewId: 'presetDefault2',
  },
  {
    foci: 'PERCENT_ON_TRACK_IN_CREDITS',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCredits1',
  },
  {
    foci: 'PERCENT_ON_TRACK_IN_CREDITS',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetCredits2',
  },
  {
    foci: 'SCHED_TO_END_TERM_ON_TRACK',
    gridType: ['hs', 'summer_hs', 'kTo12', 'summer_kTo12', '6To12', 'summer_6To12', 'charter', 'summer_charter'],
    gridViewId: 'presetCredits1',
  },
  {
    foci: 'SCHED_TO_END_TERM_ON_TRACK',
    gridType: ['transfer', 'summer_transfer', 'aim', 'summer_aim'],
    gridViewId: 'presetCredits4',
  },
  {
    foci: 'YTD_ATTD',
    gridType: ['kTo8', 'summer_kTo8', 'es', 'summer_es', 'ms', 'summer_ms'],
    gridViewId: 'presetAttendance1',
  },
  {
    foci: 'YTD_ATTD',
    gridType: [
      'hs',
      'summer_hs',
      'kTo12',
      'summer_kTo12',
      '6To12',
      'summer_6To12',
      'charter',
      'summer_charter',
      'aim',
      'summer_aim',
      'transfer',
      'summer_transfer',
    ],
    gridViewId: 'presetAttendance2',
  },
  {
    foci: 'ACADEMIC_ELA_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'ms', 'summer_ms',
      '6To8', 'summer_6To8',
      'kTo8', 'summer_kTo8',
    ],
    gridViewId: 'presetAcademics3',
  },
  {
    foci: 'ACADEMIC_ELA_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
    ],
    gridViewId: 'presetAcademics4',
  },
  {
    foci: 'ACADEMIC_ELA_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'es', 'summer_es',
    ],
    gridViewId: 'presetAcademics5',
  },
  {
    foci: 'ACADEMIC_MATH_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'ms', 'summer_ms',
      '6To8', 'summer_6To8',
      'kTo8', 'summer_kTo8',
    ],
    gridViewId: 'presetAcademics3',
  },
  {
    foci: 'ACADEMIC_MATH_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'kTo12', 'summer_kTo12',
      '6To12', 'summer_6To12',
    ],
    gridViewId: 'presetAcademics4',
  },
  {
    foci: 'ACADEMIC_MATH_STATE_EXAM_PERCENT_PROFICIENT',
    gridType: [
      'es', 'summer_es',
    ],
    gridViewId: 'presetAcademics5',
  },

];

export const GRIDVIEW_FILTER_MAPPING = [
  {
    filterKey: 'CURRENT_ACCOUNTABLE',
    filters: [
      {
        filter: 'CURRENT_ACCOUNTABLE',
        filterValue: ['true'],
      },
    ],
  },
  {
    filterKey: 'GRADES',
    filters: [
      {
        filter: 'STATUS',
        filterValue: 'ALL', // no filter
      },
    ],
  },
  {
    filterKey: 'ACTIVE_ONLY',
    filters: [
      {
        filter: 'STATUS',
        filterValue: 'A',
        filterDetails: 'Active students',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'NO_GRADE',
    filterKey: 'NO_GRADE',
    filters: [
      {
        filter: 'GRADE',
        filterValue: 'ALL', // no filter
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade K',
    filterKey: 'GRADE_K',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '0K',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 1',
    filterKey: 'GRADE_1',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '01',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 2',
    filterKey: 'GRADE_2',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '02',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 3',
    filterKey: 'GRADE_3',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '03',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 4',
    filterKey: 'GRADE_4',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '04',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 5',
    filterKey: 'GRADE_5',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '05',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 6',
    filterKey: 'GRADE_6',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '06',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 7',
    filterKey: 'GRADE_7',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '07',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 8',
    filterKey: 'GRADE_8',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '08',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 9',
    filterKey: 'GRADE_9',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '09',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 10',
    filterKey: 'GRADE_10',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '10',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 11',
    filterKey: 'GRADE_11',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '11',
      },
    ],
  },
  {
    groupingFilterKey: 'GRADE',
    groupingFilterValue: 'Grade 12',
    filterKey: 'GRADE_12',
    filters: [
      {
        filter: 'GRADE',
        filterValue: '12',
      },
    ],
  },
  {
    filterKey: 'CLASS_NO_DATA',
    filters: [
      {
        filter: 'CLASS',
        filterValue: null, // no data?
      },
    ],
  },
  {
    filterKey: 'CLASS_SENIOR',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2025', // TODO: Dynamic COHORT YEAR?
      },
    ],
  },
  {
    filterKey: 'CLASS_SENIOR_HS',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2025', // TODO: Dynamic COHORT YEAR?
      },
    ],
  },
  {
    filterKey: 'CLASS_JUNIOR',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2026',
      },
    ],
  },
  {
    filterKey: 'CLASS_JUNIOR_HS',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2026',
      },
    ],
  },
  {
    filterKey: 'CLASS_SOPHOMORE',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2027',
      },
    ],
  },
  {
    filterKey: 'CLASS_SOPHOMORE_HS',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2027',
      },
    ],
  },
  {
    filterKey: 'CLASS_FRESHMAN',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2028',
      },
    ],
  },
  {
    filterKey: 'CLASS_FRESHMAN_HS',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2028',
      },
    ],
  },
  {
    filterKey: 'CLASS_UPCOMING_FRESHMAN',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2029',
      },
    ],
  },
  {
    filterKey: 'CLASS_FRESHMAN_PLUS_2',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '20230',
      },
    ],
  },
  {
    filterKey: 'CLASS_FRESHMAN_PLUS_3',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2031',
      },
    ],
  },
  {
    filterKey: 'CLASS_FRESHMAN_PLUS_4',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2032',
      },
    ],
  },
  {
    filterKey: 'CLASS_FRESHMAN_PLUS_5',
    filters: [
      {
        filter: 'CLASS',
        filterValue: '2033',
      },
    ],
  },
  {
    filterKey: 'CLASS_PLUS',
    filters: [
      {
        filter: 'CLASS',
        filterValue: ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017'],
      },
    ],
  },
  {
    filterKey: 'ETHNICITY_ASIAN',
    filters: [
      {
        filter: 'ETHNICITY',
        filterValue: 'Asian',
      },
    ],
  },
  {
    groupingFilterKey: 'ETHNICITY',
    groupingFilterValue: 'Latino',
    filterKey: 'ETHNICITY_LATINO',
    filters: [
      {
        filter: 'ETHNICITY',
        filterValue: 'Hispanic/Latino',
      },
    ],
  },
  {
    filterKey: 'ETHNICITY_MULTI',
    filters: [
      {
        filter: 'ETHNICITY',
        filterValue: 'Multi-Racial',
      },
    ],
  },
  {
    filterKey: 'ETHNICITY_UNKNOWN',
    filters: [
      {
        filter: 'ETHNICITY',
        filterValue: 'Unknown',
      },
    ],
  },
  {
    filterKey: 'ETHNICITY_WHITE',
    filters: [
      {
        filter: 'ETHNICITY',
        filterValue: 'White',
      },
    ],
  },
  {
    filterKey: 'ETHNICITY_BLACK',
    filters: [
      {
        filter: 'ETHNICITY',
        filterValue: 'Black',
      },
    ],
  },
  {
    filterKey: 'ETHNICITY_NATIVEAMERICAN',
    filters: [
      {
        filter: 'ETHNICITY',
        filterValue: 'Native American',
      },
    ],
  },
  {
    filterKey: 'ETHNICITY_PACIFIC_ISLANDER',
    filters: [
      {
        filter: 'ETHNICITY',
        filterValue: 'Pacific Islander', // TODO: Is this valid ??
      },
    ],
  },
  {
    filterKey: 'GENDER_X',
    filters: [
      {
        filter: 'GENDER',
        filterValue: 'X',
      },
    ],
  },
  {
    filterKey: 'GENDER_MALE',
    filters: [
      {
        filter: 'GENDER',
        filterValue: 'M',
      },
    ],
  },
  {
    filterKey: 'GENDER_FEMALE',
    filters: [
      {
        filter: 'GENDER',
        filterValue: 'F',
      },
    ],
  },
  {
    groupingFilterKey: 'SPECIAL_POPULATION_STATUS',
    groupingFilterValue: 'SWD only',
    filterKey: 'SPED',
    filters: [
      {
        filter: 'IS_SPED',
        filterValue: 'true',
      },
      {
        filter: 'IS_ELL',
        filterValue: 'false',
        applyForFilter: 'false',
      },
    ],
  },
  {
    groupingFilterKey: 'SPECIAL_POPULATION_STATUS',
    groupingFilterValue: 'Neither SWD or ML',
    filterKey: 'NONE_SPED_ELL',
    filters: [
      {
        filter: 'IS_SPED',
        filterValue: 'false',
      },
      {
        filter: 'IS_ELL',
        filterValue: 'false',
      },
    ],
  },
  {
    groupingFilterKey: 'SPECIAL_POPULATION_STATUS',
    groupingFilterValue: 'ML only',
    filterKey: 'ELL',
    filters: [
      {
        filter: 'IS_SPED',
        filterValue: 'false',
        applyForFilter: 'false',
      },
      {
        filter: 'IS_ELL',
        filterValue: 'true',
      },
    ],
  },
  {
    groupingFilterKey: 'SPECIAL_POPULATION_STATUS',
    groupingFilterValue: 'SWD and ML',
    filterKey: 'SPED_ELL',
    filters: [
      {
        filter: 'IS_SPED',
        filterValue: 'true',
      },
      {
        filter: 'IS_ELL',
        filterValue: 'true',
      },
    ],
  },
  {
    filterKey: 'SWD',
    filters: [
      {
        filter: 'SWD_STATUS',
        filterValue: 'SWD',
      },
    ],
  },
  {
    filterKey: 'NOT_SWD',
    filters: [
      {
        filter: 'SWD_STATUS',
        filterValue: 'Not SWD',
      },
    ],
  },
  {
    filterKey: 'ML',
    filters: [
      {
        filter: 'ML_STATUS',
        filterValue: 'ML',
      },
    ],
  },
  {
    filterKey: 'NOT_ML',
    filters: [
      {
        filter: 'ML_STATUS',
        filterValue: 'Not ML',
      },
    ],
  },
  {
    filterKey: 'RISK_GROUP_SEVERE',
    filters: [
      {
        filter: 'DOE_RISK_GROUP_ATT',
        filterValue: 'Severely chronically absent',
      },
    ],
  },
  {
    filterKey: 'RISK_GROUP_AT_RISK',
    filters: [
      {
        filter: 'DOE_RISK_GROUP_ATT',
        filterValue: 'At Risk',
      },
    ],
  },
  {
    filterKey: 'RISK_GROUP_NOT_AT_RISK',
    filters: [
      {
        filter: 'DOE_RISK_GROUP_ATT',
        filterValue: 'Not At Risk',
      },
    ],
  },
  {
    filterKey: 'RISK_GROUP_CHRONIC',
    filters: [
      {
        filter: 'DOE_RISK_GROUP_ATT',
        filterValue: 'Chronically absent',
      },
    ],
  },
  {
    filterKey: 'RISK_GROUP_NO_DATA',
    filters: [
      {
        filter: 'DOE_RISK_GROUP_ATT',
        filterValue: null,
      },
    ],
  },
  {
    filterKey: 'RISK_GROUP_NONE',
    filters: [
      {
        filter: 'DOE_RISK_GROUP_ATT',
        filterValue: 'No DOE risk group',
      },
    ],
  },
  {
    groupingFilterKey: 'HOUSING_STATUS',
    groupingFilterValue: 'None',
    filterKey: 'HOUSING_STATUS_N',
    filters: [
      {
        filter: 'HOUSING_STATUS',
        filterValue: null,
      },
    ],
  },
  {
    filterKey: 'HOUSING_STATUS_A',
    filters: [
      {
        filter: 'HOUSING_STATUS',
        filterValue: 'Awaiting foster care placement',
      },
    ],
  },
  {
    filterKey: 'HOUSING_STATUS_S',
    filters: [
      {
        filter: 'HOUSING_STATUS',
        filterValue: 'Shelter',
      },
    ],
  },
  {
    filterKey: 'HOUSING_STATUS_T',
    filters: [
      {
        filter: 'HOUSING_STATUS',
        filterValue: 'Other temporary living situation',
      },
    ],
  },
  {
    filterKey: 'HOUSING_STATUS_D',
    filters: [
      {
        filter: 'HOUSING_STATUS',
        filterValue: 'Doubled up',
      },
    ],
  },
  {
    filterKey: 'HOUSING_STATUS_H',
    filters: [
      {
        filter: 'HOUSING_STATUS',
        filterValue: 'Hotel / Motel',
      },
    ],
  },
  {
    filterKey: 'HOUSING_STATUS_P',
    filters: [
      {
        filter: 'HOUSING_STATUS',
        filterValue: 'Permanent housing status',
      },
    ],
  },
  {
    filterKey: 'HOUSING_SECURE',
    filters: [
      {
        filter: 'HOUSING_INSECURE',
        filterValue: 'false',
      },
    ],
  },
  {
    filterKey: 'HOUSING_SECURE',
    filters: [
      {
        filter: 'HOUSING_INSECURE',
        filterValue: 'true',
      },
    ],
  },
  {
    groupingFilterKey: 'HOUSING_INSECURE',
    groupingFilterValue: 'Yes',
    filterKey: 'HOUSING_INSECURE',
    filters: [
      {
        filter: 'HOUSING_INSECURE',
        filterValue: 'true',
      },
    ],
  },
  {
    groupingFilterKey: 'HOUSING_INSECURE',
    groupingFilterValue: 'No',
    filterKey: 'HOUSING_INSECURE',
    filters: [
      {
        filter: 'HOUSING_INSECURE',
        filterValue: 'false',
      },
    ],
  },
  {
    filterKey: 'PLANNED_DIPLOMA_CAT_LOCAL',
    filters: [
      {
        filter: 'PLANNED_DIPLOMA_CAT',
        filterValue: 'Planned Local',
      },
    ],
  },
  {
    filterKey: 'PLANNED_DIPLOMA_CAT_REG',
    filters: [
      {
        filter: 'PLANNED_DIPLOMA_CAT',
        filterValue: 'Planned Regents',
      },
    ],
  },
  {
    filterKey: 'PLANNED_DIPLOMA_CAT_ADV_REG',
    filters: [
      {
        filter: 'PLANNED_DIPLOMA_CAT',
        filterValue: 'Planned Advanced Regents',
      },
    ],
  },
  {
    filterKey: 'PLANNED_DIPLOMA_CAT_GRAD',
    filters: [
      {
        filter: 'PLANNED_DIPLOMA_CAT',
        filterValue: 'Graduates',
      },
    ],
  },
  {
    filterKey: 'PLANNED_DIPLOMA_CAT_DNEG',
    filters: [
      {
        filter: 'PLANNED_DIPLOMA_CAT',
        filterValue: 'Negative Discharge',
      },
    ],
  },
  {
    filterKey: 'PLANNED_DIPLOMA_CAT_INCOMPLETE',
    filters: [
      {
        filter: 'PLANNED_DIPLOMA_CAT',
        filterValue: 'Plan Incomplete',
      },
    ],
  },
  {
    filterKey: 'PLANNED_DIPLOMA_CAT_NON_GRAD',
    filters: [
      {
        filter: 'PLANNED_DIPLOMA_CAT',
        filterValue: 'Planned Non-Grad',
      },
    ],
  },
  {
    groupingFilterKey: 'PLANNED_GRAD_DATE_CAT',
    groupingFilterValue: 'Planned Jan 2023 Grads',
    filterKey: 'PLANNED_GRAD_DATE_CAT_JAN_CURRSY',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: 'Jan 2023',
      },
    ],
  },

  {
    filterKey: 'PLANNED_GRAD_DATE_CAT_INCOMPLETE',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: 'Plan Incomplete',
      },
    ],
  },
  {
    groupingFilterKey: 'PLANNED_GRAD_DATE_CAT',
    groupingFilterValue: 'Planned 2026 Grads',
    filterKey: 'PLANNED_GRAD_DATE_CAT_NEXTSY',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: '2026',
      },
    ],
  },
  {
    filterKey: 'PLANNED_GRAD_DATE_CAT_NON_GRAD',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: 'Planned Non-Grad',
      },
    ],
  },
  {
    groupingFilterKey: 'PLANNED_GRAD_DATE_CAT',
    groupingFilterValue: 'Planned 2027 Grads',
    filterKey: 'PLANNED_GRAD_DATE_CAT_TWOSY',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: '2027',
      },
    ],
  },
  {
    groupingFilterKey: 'PLANNED_GRAD_DATE_CAT',
    groupingFilterValue: 'Negative Discharge',
    filterKey: 'PLANNED_GRAD_DATE_CAT_DNEG',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: 'Negative Discharge',
      },
    ],
  },
  {
    groupingFilterKey: 'PLANNED_GRAD_DATE_CAT',
    groupingFilterValue: 'Planned Aug 2025 Grads',
    filterKey: 'PLANNED_GRAD_DATE_CAT_AUG_CURRSY',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: 'Aug 2025',
      },
    ],
  },
  {
    groupingFilterKey: 'PLANNED_GRAD_DATE_CAT',
    groupingFilterValue: 'Planned Mar 2025 Grads',
    filterKey: 'PLANNED_GRAD_DATE_CAT_MARCH_CURRSY',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: 'Mar 2025',
      },
    ],
  },
  {
    filterKey: 'PLANNED_GRAD_DATE_CAT_THREESY',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: '2028',
      },
    ],
  },
  {
    filterKey: 'PLANNED_GRAD_DATE_CAT_JUNE_CURRSY',
    filters: [
      {
        filter: 'PLANNED_GRAD_DATE_CAT',
        filterValue: 'Planned June 2025 Grads',
      },
    ],
  },
  {
    filterKey: 'PLANS_IN_NEED_OF_REVIEW',
    filters: [
      {
        filter: 'GRAD_PLAN_STATUS',
        filterValue: ['More Ambitious Plan Possible', 'Current Plan At Risk'],
        filterDetails: "Graduation Plan Status is 'More Ambitious Plan Possible' or 'Current Plan At Risk'",
      },
    ],
  },
  {
    filterKey: 'NEED_GRAD_PLANS',
    filters: [
      {
        filter: 'GRAD_PLAN_STATUS',
        filterValue: 'Missing Grad Plan',
        filterDetails: "Graduation Plan Status is 'Missing Grad Plan'",
      },
    ],
  },
  {
    filterKey: 'NEED_COURSE_PLANS',
    filters: [
      {
        filter: 'COURSE_SCHEDULING_STATUS_FOR_CURR_TERM',
        filterValue: 'Missing plans to address gaps',
        filterDetails: "Course Scheduling Status for Curr Term is 'Missing plans to address gaps'",
      },
    ],
  },
  {
    filterKey: 'NEED_REGENTS_PLANS',
    filters: [
      {
        filter: 'REGENTS_PLANNING_STATUS',
        filterValue: 'Missing Plans to Address Needs',
        filterDetails: "Regents Planning Status is 'Missing Plans to Address Needs'",
      },
    ],
  },
  {
    filterKey: 'NEED_REGENTS_SUPPORTS', // TOTAL_STUDENTS_PLANNED_FOR_REGENTS does not have supporting grid column, but population filter is considered covered by this focus filter logic. TOTAL_STUDENTS_PLANNED_FOR_REGENTS includes STATUS: 'A'
    filters: [
      {
        filter: 'FULLY_SUPPORTED_FOR_REGENTS',
        filterValue: 'No',
        filterDetails: "Fully Supported for Upcoming Regents is 'No'",
      },
      {
        filter: 'STATUS',
        filterValue: 'A',
        filterDetails: 'Active students',
      },
    ],
  },
  {
    filterKey: 'ADMITTED_WITHIN_LAST_20_DAYS',
    filters: [
      {
        filter: 'ADMITTED_WITHIN_LAST_20_DAYS',
        filterValue: 'true',
        filterDetails: 'Admitted within last 20 days',
      },
    ],
  },
  {
    filterKey: 'SHELTER_ACTIVE_ONLY_STUDENTS',
    gridViewId: 'presetShelterDefault1',
    filters: [
      {
        filter: 'STATUS',
        filterValue: 'A',
        filterDetails: 'Shelter active students',
      },
    ],
  },
  {
    filterKey: 'SHELTER_ADMITTED_WITHIN_LAST_20_DAYS',
    gridViewId: 'presetShelterDefault1',
    filters: [
      {
        filter: 'SHELTER_ADMITTED_WITHIN_LAST_20_DAYS',
        filterValue: 'true',
        filterDetails: 'Shelter admitted within last 20 days',
      },
    ],
  },
  {
    filterKey: 'SHELTER_PERCENT_IS_SWD_STUDENT',
    gridViewId: 'presetShelterDefault1',
    filters: [
      {
        filter: 'IS_SPED',
        filterValue: 'true',
        filterDetails: 'Shelter SWD students',
      },
    ],
  },
  {
    filterKey: 'SHELTER_ELL_STUDENTS',
    gridViewId: 'presetShelterDefault1',
    filters: [
      {
        filter: 'IS_ELL',
        filterValue: 'true',
        filterDetails: 'Shelter ML students',
      },
    ],
  },
  {
    filterKey: 'SHELTER_CHRONIC_ABSENTEEISM_RATE',
    gridViewId: 'presetShelterAttendance1',
    filters: [
      {
        filter: 'DOE_RISK_GROUP_ATT',
        filterValue: ['Chronically absent', 'Severely chronically absent'],
        filterDetails: 'Shelter chronically absent',
      },
    ],
  },
  {
    filterKey: 'SHELTER_ABSENT_PAST_2_PLUS_DAYS',
    gridViewId: 'presetShelterAttendance1',
    filters: [
      {
        filter: 'CONSECUTIVE_ABSENCES',
        type: 'greaterThanOrEqual',
        value: 2,
        filterDetails: 'Shelter consecutive absences',
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_HAS_ATTENDANCE_ALERT',
    filters: [
      {
        filter: 'CORE_4_ATTENDANCE_ALERT',
        filterValue: ['Below 80%'],
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_HAS_GRADES_ALERT',
    filters: [
      {
        filter: 'CORE_4_GRADES_ALERT',
        filterValue: ['Failing 1+'],
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_HAS_CREDITS_ALERT',
    filters: [
      {
        filter: 'CORE_4_CREDITS_ALERT',
        filterValue: ['Gaps'],
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_HAS_REGENTS_ALERT',
    filters: [
      {
        filter: 'CORE_4_REGENTS_ALERT',
        filterValue: ['Behind'],
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ_0',
    filters: [
      {
        filter: 'CORE_4_ALERTS',
        filterValue: ['0'],
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ_1',
    filters: [
      {
        filter: 'CORE_4_ALERTS',
        filterValue: ['1'],
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ_2',
    filters: [
      {
        filter: 'CORE_4_ALERTS',
        filterValue: ['2'],
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ_3',
    filters: [
      {
        filter: 'CORE_4_ALERTS',
        filterValue: ['3'],
      },
    ],
  },
  {
    filterKey: 'COUNT_STUDENTS_WITH_CORE_4_ALERTS_VIZ_4',
    filters: [
      {
        filter: 'CORE_4_ALERTS',
        filterValue: ['4'],
      },
    ],
  },
  {
    filterKey: 'VERY_LIKELY_OR_GRADUATED_COUNT',
    filters: [
      {
        filter: 'CLASS',
        filterValue: CurrentSchoolYear.ENDFULL,
      },
      {
        filter: 'CORE_4_GRAD_PROJECTION',
        filterValue: ['0: Graduate', '1: Very Likely'],
      },
    ],
  },
  {
    filterKey: 'LIKELY_TO_GRADUATE_COUNT',
    filters: [
      {
        filter: 'CLASS',
        filterValue: CurrentSchoolYear.ENDFULL,
      },
      {
        filter: 'CORE_4_GRAD_PROJECTION',
        filterValue: '2: Likely',
      },
    ],
  },
  {
    filterKey: 'PROBABLE_TO_GRADUATE_COUNT',
    filters: [
      {
        filter: 'CLASS',
        filterValue: CurrentSchoolYear.ENDFULL,
      },
      {
        filter: 'CORE_4_GRAD_PROJECTION',
        filterValue: '3: Probable',
      },
    ],
  },
  {
    filterKey: 'POSSIBLE_TO_GRADUATE_COUNT',
    filters: [
      {
        filter: 'CLASS',
        filterValue: CurrentSchoolYear.ENDFULL,
      },
      {
        filter: 'CORE_4_GRAD_PROJECTION',
        filterValue: '4: Possible',
      },
    ],
  },
  {
    filterKey: 'NEEDS_REVIEW_OR_D_NEG_COUNT',
    filters: [
      {
        filter: 'CLASS',
        filterValue: CurrentSchoolYear.ENDFULL,
      },
      {
        filter: 'CORE_4_GRAD_PROJECTION',
        filterValue: ['5: Needs Review', '6: Negative Discharge'],
      },
    ],
  },
];
